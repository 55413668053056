import axios from 'axios';
import config from '../config';

const gastosApi = axios.create({
  baseURL: `${config.baseUrl}gastos/api/v1/gastos`
});

export const getAllGastos = () => {
  return gastosApi.get('/');
};

export const createGasto = (formData) => {
  return gastosApi.post('/', formData);
};

export const deleteGasto = (gastoId) => {
    return gastosApi.delete(`/${gastoId}`);
  };

  export const updateGasto = (gastoid, updateData) => {
    return gastosApi.put(`/${gastoid}/`,updateData)
  };
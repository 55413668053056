import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../styles/LabTabs.css';
import { AlquileresList } from './AlquileresList';
import { AddAlquiler } from './addAlquiler';


export default function AlquileresLabTabs(props) {
    const [value, setValue] = useState('1');
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
      if (shouldRefresh) {
        setShouldRefresh(false); // Resetea el estado
        handleChange(null, '1'); // Cambia automáticamente al tab de "Lista de Propiedades"
      }
    }, [shouldRefresh]);
  
    return (
      <div className="centered-container">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example" className="items_center">
                <Tab label={props.item1} value="1" />
                <Tab label={props.item2} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1"><AlquileresList/></TabPanel>
            <TabPanel value="2"><AddAlquiler setShouldRefresh={setShouldRefresh}/></TabPanel>  {/*<AddClients setShouldRefresh={setShouldRefresh}/>*/}
          </TabContext>
        </Box>
      </div>
    );
  }
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@100&family=Fraunces&family=Lato:ital@1&family=PT+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` *{
    font-family: 'DM Sans', sans-serif;
    font-family: 'Fraunces', serif;
    font-family: 'Lato', sans-serif;
    font-family: 'PT Sans', sans-serif;
}

.bar{
    background-color: #fff;
    z-index: 3;
}

.boxnav{
    margin-bottom: 90px;
}
.button_calendar{
    color: #000;
}
.button_calendar:hover{
        box-shadow: 0 0 0 1px #162b4f;
}
.text{
    text-align: center;
    box-shadow: 10px solid #000;
}
.button_not{
    color: #000;
    border-radius: 90px;
}
.button_not:hover{
    box-shadow: 0 0 0 1px #162b4f;
}`, "",{"version":3,"sources":["webpack://./src/styles/navBar.css"],"names":[],"mappings":"CAGA;IACI,kCAAkC;IAClC,8BAA8B;IAC9B,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;QACQ,6BAA6B;AACrC;AACA;IACI,kBAAkB;IAClB,2BAA2B;AAC/B;AACA;IACI,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,6BAA6B;AACjC","sourcesContent":[" @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@100&family=Fraunces&family=Lato:ital@1&family=PT+Sans&display=swap');\n\n\n*{\n    font-family: 'DM Sans', sans-serif;\n    font-family: 'Fraunces', serif;\n    font-family: 'Lato', sans-serif;\n    font-family: 'PT Sans', sans-serif;\n}\n\n.bar{\n    background-color: #fff;\n    z-index: 3;\n}\n\n.boxnav{\n    margin-bottom: 90px;\n}\n.button_calendar{\n    color: #000;\n}\n.button_calendar:hover{\n        box-shadow: 0 0 0 1px #162b4f;\n}\n.text{\n    text-align: center;\n    box-shadow: 10px solid #000;\n}\n.button_not{\n    color: #000;\n    border-radius: 90px;\n}\n.button_not:hover{\n    box-shadow: 0 0 0 1px #162b4f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { getAllProperties } from '../api/propiedades.api';
import config from '../config';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createValores, getValoresByPropiedad, deleteValores } from '../api/valores.api';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ValorPropiedadForm = ({ setShouldRefreshValores, onChangeTab }) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [propiedades, setPropiedades] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('todas');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [valor, setValor] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [valoresPropiedad, setValoresPropiedad] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [valorEliminado, setValorEliminado] = useState(false);

  const loadProperties = async () => {
    try {
      const propertiesData = await getAllProperties();
      setPropiedades(propertiesData);
    } catch (error) {
      console.error('Error al cargar propiedades:', error);
    }
  };

  // Elimina el array de dependencias del useEffect
  useEffect(() => {

    loadProperties();
    // Vuelve a cargar los valores de propiedad cuando cambian los valores de valoresPropiedad
    const loadValores = async () => {
      if (selectedProperty !== 'todas') {
        try {
          const valores = await getValoresByPropiedad(selectedProperty);
          setValoresPropiedad(valores);
        } catch (error) {
          console.error('Error al cargar valores de propiedad:', error);
        }
      } else {
        setValoresPropiedad([]); // Limpiar los valores si se selecciona 'selecciona una propiedad'
      }
    };
    loadValores();
    setFormSubmitted(false);
    setValorEliminado(false);
  }, [selectedProperty, formSubmitted, valorEliminado]);



  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectProperty = async (propertyId) => {
    setSelectedProperty(propertyId);
    setIsDropdownOpen(false);

    if (propertyId !== 'todas') {
      try {
        const valores = await getValoresByPropiedad(propertyId);
        setValoresPropiedad(valores);
      } catch (error) {
        console.error('Error al cargar valores de propiedad:', error);
      }
    } else {
      setValoresPropiedad([]); // Limpiar los valores si se selecciona 'selecciona una propiedad'
    }
  };

  const handleDateSelect = (selectInfo) => {
    const { start, end } = selectInfo;
    const selectedRange = getDatesArray(start, end);
    setSelectedDates(selectedRange);
  };

  const getDatesArray = (start, end) => {
    const datesArray = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      datesArray.push(currentDate);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }

    return datesArray;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    try {
      // Envía la solicitud para crear un nuevo valor
      await createValores({
        propiedad: selectedProperty,
        fecha_desde: fechaInicio,
        fecha_hasta: fechaFin,
        valor_diario: valor,
      });

      toast.success("Valor de Propiedad ingresado con exito");
    } catch (error) {
      toast.error("Hubo un error al ingresar valor de propiedad");
      console.error('Error al crear un nuevo valor:', error);
    }
  };

  const handleDeleteClick = async (valorId) => {
    try {
      // Llama a tu API para eliminar el valor con el ID proporcionado
      await deleteValores(valorId);
      setValorEliminado(true);
      toast.success("Valor de Propiedad eliminado con exito");
    } catch (error) {
      toast.error("Hubo un error al eliminar valor de propiedad");
      console.error('Error al eliminar el valor:', error);
      // Maneja el error según sea necesario
    }
  };

  function sumarUnDia(fecha) {
    const fechaOriginal = new Date(fecha);
    fechaOriginal.setDate(fechaOriginal.getDate() + 1);
  
    const dia = fechaOriginal.getDate() + 1;
    const mes = fechaOriginal.getMonth() + 1; // Sumar 1 porque los meses en JavaScript son base 0
    const anio = fechaOriginal.getFullYear();
  
    // Asegurarse de que el día y el mes tengan dos dígitos
    const diaString = dia < 10 ? `0${dia}` : dia.toString();
    const mesString = mes < 10 ? `0${mes}` : mes.toString();
  
    return `${anio}-${mesString}-${diaString}`;
  }

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ flex: 1, marginRight: '20px', paddingLeft: '0px', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Calendario */}
        <b>INGRESE VALORES DE PROPIEDAD POR RANGOS DE FECHA</b>
        <p style={{color:'red', fontSize:'11px'}}>Si no se ingresa un valor por rango, se tomará por defecto el valor diario asignado al añadir la propiedad</p>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
            <label style={{ marginRight: '10px', fontWeight: 'bold' }}>Propiedad:</label>
            <div className={`custom-select ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
              <div className="select-value">
                {selectedProperty === 'todas' ? 'Selecciona una propiedad' : propiedades.find(propiedad => propiedad.id === selectedProperty)?.direccion}
                <ArrowDropDownIcon style={{ marginLeft: 'auto', backgroundColor: 'white', zIndex: '1' }} />
              </div>
              <div className={`options ${isDropdownOpen ? 'visible' : ''}`}>
                <div className="option" onClick={() => handleSelectProperty('todas')}>Selecciona una propiedad</div>
                {propiedades.map(propiedad => (
                  <div key={propiedad.id} className={`option ${selectedProperty === propiedad.id ? 'selected' : ''}`} onClick={() => handleSelectProperty(propiedad.id)}>
                    <div className="custom-option">
                      <img
                        src={
                          propiedad.imagen != null
                            ? propiedad.imagen
                            : `${config.baseUrl}media/media/ginmueble.jpg`
                        }
                        alt={propiedad.imagen ? 'Property' : 'Imagen de Propiedad Genérica'}
                        style={{ width: '60px', height: '60px', marginRight: '30px' }}
                      />
                      <div className="textos">
                        <p className="titulo">{propiedad.direccion}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <label><b>Valor: </b></label>
          <input type="number"
            value={valor}
            onChange={(e) => setValor(e.target.value)} />
          <br></br>
          <label><b>Fecha Inicio: </b></label>
          <input type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)} />
          <br></br>
          <label><b>Fecha Fin: </b></label>
          <input type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)} />
          <br></br>
          <button type="submit">Guardar</button>
        </form>
        <div style={{ flex: 1 }}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          selectable={true}
          select={handleDateSelect}
          locale='es'
          events={[
            ...selectedDates.map((date) => ({ title: 'Seleccionado', date })),
            ...valoresPropiedad
              .filter((valor) => valor.propiedad === selectedProperty) // Filtrar por propiedad seleccionada
              .map((valor) => {
                const fechaHasta = sumarUnDia(valor.fecha_hasta);
                return {
                  title: `Valor: $${Number(valor.valor_diario).toFixed(2).toString()}`,
                  start: valor.fecha_desde,
                  end: fechaHasta,
                  color: 'green',
                };
              }),
          ]}
        />
        </div>
      </div>
      <div style={{ flex: '0 0 220px', backgroundColor: '#f0f0f0', padding: '20px', marginLeft: 'auto', textAlign: 'left', height: 'auto', overflow: 'auto', overflowX: 'hidden' }}>
        {/* Menú de información */}
        {selectedProperty && (
          <div>
          {selectedProperty && (
            <div>
              {selectedProperty !== 'todas' && (
                <div>
                  <p>Valores asignados a la propiedad:</p>
                    {valoresPropiedad
                      .filter((valor) => valor.propiedad === selectedProperty) // Filtrar por propiedad seleccionada
                      .map((valor) => (
                        <div style={{background:'white', marginBottom:'10px'}} key={valor.id}>
                          <b>Valor:</b> ${valor.valor_diario}, <b>Desde:</b> {valor.fecha_desde}, <b>Hasta:</b> {valor.fecha_hasta}
                          <Button onClick={() => handleDeleteClick(valor.id)}>
                              ELIMINAR
                          </Button>
                        </div>
                      ))}
                </div>
              )}
              {/* Display other property details */}
            </div>
          )}
            {/* Display other property details */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ValorPropiedadForm;

import axios from 'axios';
import config from '../config';

const valoresApi = axios.create({
  baseURL: `${config.baseUrl}valores/api/v1/valores`,
});

export const createValores= (formData) => {
    return valoresApi.post('/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Importante establecer el tipo de contenido adecuado
      }
    }); 
  };
  
  
  export const deleteValores = (id) => {
    return valoresApi.delete(`/${id}`);
  };
  
  export const updateValores = (id, valor) => {
    return valoresApi.put(`/${id}/`,valor)
  };
  

export const getValoresByPropiedad = async (propiedadId) => {
  try {
    const response = await valoresApi.get(`?propiedad=${propiedadId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import esLocale from '@fullcalendar/core/locales/es';
import { getAllAlquileres } from '../api/alquileres.api';
import { getInquilinoById } from '../api/clientes.api';
import { getAllProperties } from '../api/propiedades.api';
import config from '../config';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import '../styles/Calendar2.css';

function CalendarAlq() {
  const [propiedades, setPropiedades] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('todas');
  const [alquileres, setAlquileres] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedEventProperty, setSelectedEventProperty] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    Promise.all([getAllAlquileres(), getAllProperties()])
      .then(([alquileresResponse, propiedadesResponse]) => {
        const alquileresData = alquileresResponse.data;
        const propiedadesData = propiedadesResponse;

        Promise.all(alquileresData.map(alquiler => getInquilinoById(alquiler.inquilino)))
          .then(inquilinos => {
            const alquileresConInquilinos = alquileresData.map((alquiler, index) => ({
              ...alquiler,
              inquilino: inquilinos[index],
            }));

            setAlquileres(alquileresConInquilinos);
            setPropiedades(propiedadesData);
          })
          .catch(error => {
            console.error('Error al obtener los datos de los inquilinos:', error);
          });
      })
      .catch(error => {
        console.error('Error al obtener datos:', error);
      });
  }, []);

  useEffect(() => {
    const alquileresFiltrados = selectedProperty === 'todas'
      ? alquileres
      : alquileres.filter(alquiler => alquiler.propiedad === parseInt(selectedProperty));

    const eventos = alquileresFiltrados.map(alquiler => {
      const fechaFin = new Date(alquiler.fecha_fin);
      fechaFin.setDate(fechaFin.getDate() + 1);

      const dia = fechaFin.getDate() + 1;
      const mes = fechaFin.getMonth() + 1;
      const anio = fechaFin.getFullYear();

      const formattedDia = dia >= 10 ? dia : `0${dia}`;
      const formattedMes = mes >= 10 ? mes : `0${mes}`;

      const randomColor = getRandomColor(); // Generate a random color

      return {
        alq: alquiler.id,
        title: `${alquiler.inquilino.nombre} ${alquiler.inquilino.apellido} | ${propiedades.find(propiedad => propiedad.id === alquiler.propiedad).direccion} | $${alquiler.valor}`,
        start: alquiler.fecha_inicio,
        end: `${anio}-${formattedMes}-${formattedDia}`,
        color: randomColor, 
        propiedad: alquiler.propiedad,
        inquilino: `${alquiler.inquilino.nombre} ${alquiler.inquilino.apellido}`,
        valorPromedio: alquiler.valorPromedio,
        valorAlquiler: alquiler.valor,
        valorAdeudado: alquiler.valorAdeudado,
        cantidadDias: alquiler.cantidad_dias,
        checkin: alquiler.check_in,
        checkout: alquiler.check_out,
        metodoPago: alquiler.metodo_pago
      };
    });

    setCalendarEvents(eventos);
  }, [selectedProperty, alquileres, propiedades]);

  const handleEventClick = (eventInfo) => {
    const selectedEventId = eventInfo.event.extendedProps.alq;
    const selectedEvent = calendarEvents.find(event => event.alq === selectedEventId);

    setSelectedEventProperty(selectedEvent);

  };

  const getRandomColor = () => {
    const colors = ['#bd81e1', '#395eb1', '#27463e', '#94204d',  '#634759', '#303a66'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectProperty = (propertyId) => {
    setSelectedProperty(propertyId);
    setIsDropdownOpen(false);
  };


  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ flex: 1, marginRight: '20px', paddingLeft: '0px', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Calendario */}
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <label style={{ marginRight: '10px', fontWeight: 'bold' }}>Selecciona una propiedad:</label>
          <div className={`custom-select ${isDropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
            <div className="select-value">
              {selectedProperty === 'todas' ? 'Todas las propiedades' : propiedades.find(propiedad => propiedad.id === selectedProperty)?.direccion}
              <ArrowDropDownIcon style={{ marginLeft: 'auto', backgroundColor:'white', zIndex:'1' }} />
            </div>
            <div className={`options ${isDropdownOpen ? 'visible' : ''}`}>
              <div className="option" onClick={() => handleSelectProperty('todas')}>Todas las propiedades</div>
              {propiedades.map(propiedad => (
                <div key={propiedad.id} className={`option ${selectedProperty === propiedad.id ? 'selected' : ''}`} onClick={() => handleSelectProperty(propiedad.id)}>
                  <div className="custom-option">
                    <img
                      src={
                        propiedad.imagen != null
                          ? propiedad.imagen
                          : `${config.baseUrl}media/media/ginmueble.jpg`
                      }
                      alt={propiedad.imagen ? 'Property' : 'Imagen de Propiedad Genérica'}
                      style={{ width: '60px', height: '60px', marginRight: '30px' }}
                    />
                    <div className="textos">
                      <p className="titulo">{propiedad.direccion}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView='dayGridMonth'
            events={calendarEvents}
            locales={[esLocale]}
            locale='es'
            eventClick={handleEventClick}
            className="calendario"
            style={{ width: '100%', marginLeft: 0 }} // Adjust the left margin
          />
        </div>
      </div>
      <div style={{ flex: '0 0 220px', backgroundColor: '#f0f0f0', padding: '20px', marginLeft: 'auto', textAlign: 'left', height: 'auto', overflow: 'auto', overflowX: 'hidden' }}>
        {/* Menú de información */}
        {selectedEventProperty && (
          <div>
            <h4 style={{ marginBottom: '10px' }}>Inquilino: {selectedEventProperty.inquilino}</h4>
            {selectedEventProperty.propiedad && (
              <div>
                <img
                  src={
                    propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).imagen != null
                      ? propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).imagen
                      : `${config.baseUrl}media/media/ginmueble.jpg`
                  }
                  alt={
                    selectedEventProperty.propiedad.imagen
                      ? 'Property'
                      : 'Imagen de Propiedad Genérica'
                  }
                  style={{ width: '100%', height: 'auto', marginBottom: '10px' }}
                />
                <h4 style={{ marginTop: '0px' }}><b>{propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).direccion} / CP: {propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).codigoPostal}</b></h4>
                <p style={{fontSize:'15px'}}><b>Provincia:</b> {propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).provincia}</p>
                <p style={{fontSize:'15px'}}><b>Localidad:</b> {propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).localidad}</p>
                <p style={{fontSize:'15px'}}><b>Cantidad de dias:</b> {selectedEventProperty.cantidadDias}</p>
                <p style={{fontSize:'15px'}}><b>Valor diario promedio:</b> {selectedEventProperty.valorPromedio}</p>
                <p style={{fontSize:'15px'}}><b>Valor del alquiler:</b> {selectedEventProperty.valorAlquiler}</p>
                <p style={{fontSize:'15px'}}><b>Valor adeudado:</b> {selectedEventProperty.valorAdeudado}</p>
                {selectedEventProperty.metodoPago == '2_pagos' && (
                  <p style={{fontSize:'15px'}}><b>Metodo de pago:</b> 2 pagos</p>
                )} 
                {selectedEventProperty.metodoPago == '3_pagos' && (
                  <p style={{fontSize:'15px'}}><b>Metodo de pago:</b> 3 pagos</p>
                )}
                {(selectedEventProperty.metodoPago == 'transferencia' || selectedEventProperty.metodoPago == 'contado') && (
                  <p style={{fontSize:'15px'}}><b>Metodo de pago:</b> {selectedEventProperty.metodoPago}</p>
                )}                 
                <p style={{fontSize:'15px'}}><b>Fecha de inicio:</b> {new Date(new Date(selectedEventProperty.start).getTime() + (24 * 60 * 60 * 1000)).toLocaleDateString('es-AR')}</p>
                <p style={{fontSize:'15px'}}><b>Check-In:</b>  {selectedEventProperty.checkin}</p>
                <p style={{fontSize:'15px'}}><b>Fecha de Fin:</b> {new Date(selectedEventProperty.end).toLocaleDateString('es-AR')}</p>
                <p style={{fontSize:'15px'}}><b>Check-Out:</b>  {selectedEventProperty.checkout}</p>
                <p style={{fontSize:'15px'}}>{propiedades.find(propiedad => propiedad.id === selectedEventProperty.propiedad).descripcion}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CalendarAlq;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilos para el componente Calendar.css */
.main-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

/* Estilos para el Sidebar */
.SideBar {
  width: 200px;
}

.calendar-container {
  width: 100%;
  height: 100%; /* Añade altura del 100% al contenedor del calendario */
}

.day-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
}

.sunday {
  background-color: #ffcccc; /* Color de fondo para los domingos */
}

.saturday {
  background-color: #ccffcc; /* Color de fondo para los sábados */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Calendar.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;AACf;;AAEA,4BAA4B;AAC5B;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY,EAAE,uDAAuD;AACvE;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,qCAAqC;AAClE;;AAEA;EACE,yBAAyB,EAAE,oCAAoC;AACjE","sourcesContent":["/* Estilos para el componente Calendar.css */\n.main-container {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  padding: 20px;\n}\n\n/* Estilos para el Sidebar */\n.SideBar {\n  width: 200px;\n}\n\n.calendar-container {\n  width: 100%;\n  height: 100%; /* Añade altura del 100% al contenedor del calendario */\n}\n\n.day-cell {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 40px;\n  border: 1px solid #ccc;\n  box-sizing: border-box;\n  font-weight: bold;\n  font-size: 14px;\n}\n\n.sunday {\n  background-color: #ffcccc; /* Color de fondo para los domingos */\n}\n\n.saturday {\n  background-color: #ccffcc; /* Color de fondo para los sábados */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import axios from 'axios';
import config from '../config';

const inventarioApi = axios.create({
  baseURL: `${config.baseUrl}inventario/api/v1/inventario`,
});

export const getAllInventario = () => {
  return inventarioApi.get('/').then(response => {
    return response.data;
  });
};

export const createInventario = (inventarioData) => {
  return inventarioApi.post('/', inventarioData);
};

export const deleteInventario = (id) => {
  return inventarioApi.delete(`/${id}`);
};

export const updateInventario = (id, inventarioData) => {
  return inventarioApi.put(`/${id}/`, inventarioData);
};

export const getInventarioByPropertyId = async (propertyId) => {
  const response = await inventarioApi.get(`/?propiedad=${propertyId}`);
  return response.data;
};



import React, { useState, useEffect } from 'react';
import { createGasto } from '../api/gastos.api';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getAllProperties } from '../api/propiedades.api';
import '../styles/AddGasto.css'; // Importar el archivo CSS

export default function AgregarGastoForm({ setShouldRefresh }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    propiedad: '',
    concepto: '',
    descripcion: '',
    monto: '',
    moneda: '',
    fecha: ''
  });
  const [conceptos, setConceptos] = useState([]);
  const [propiedades, setPropiedades] = useState([]);
  const { formState: { errors } } = useForm();

  useEffect(() => {
    async function loadPropiedades() {
      const res = await getAllProperties();
      setPropiedades(res);
    }
    loadPropiedades();
  }, []);

  // Cargar los conceptos al cargar el componente
  useEffect(() => {
    // Lógica para obtener los conceptos 
    const conceptosFromBackend = [
      'Gastos de Administración',
      'Gastos de Limpieza',
      'Gastos Legales y Administrativos',
      'Compras de Equipos / Electrodomesticos',
      'Condominio',
      'Luz ( CELESC )',
      'Honorarios / Comisiones de Agentes',
      'IPTU',
      'Internet y Cable CLARO',
      'Impuestos y Tasas',
      'Mantenimiento y Reparaciones',
      'Muebles y Equipamiento',
      'Seguro de Propiedad',
      'Servicios Públicos',
      'Varios',
    ];
    setConceptos(conceptosFromBackend);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createGasto(formData);
      setShouldRefresh(true);
      navigate('/gastos');
      toast.success('Gasto creado correctamente');
    } catch (error) {
      toast.error('Error al crear gasto');
    }
  };

  return (
    <div className="containerGastoAdd">
      <div className="form-wrapper">
        <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="propiedad">Propiedad: *</label>
          <select
            className="input-select"
            name="propiedad"
            value={formData.propiedad}
            onChange={handleChange}
            required // Agrega la propiedad required aquí
          >
            <option value="">Seleccionar Propiedad</option>
            {propiedades.map((propiedad) => (
              <option key={propiedad.id} value={propiedad.id}>
                {propiedad.direccion}
              </option>
            ))}
          </select>
          {errors.propiedad && <p className="error-message">Este campo es requerido</p>}
        </div>

        <div className="form-group">
          <label htmlFor="concepto">Concepto: *</label>
          <select
            className="input-select"
            name="concepto"
            value={formData.concepto}
            onChange={handleChange}
            required // Agrega la propiedad required aquí
          >
            <option value="">Selecciona un concepto</option>
            {conceptos.map((concepto) => (
              <option key={concepto} value={concepto}>
                {concepto}
              </option>
            ))}
          </select>
          {errors.concepto && <p className="error-message">Este campo es requerido</p>}
        </div>

        <div className="form-group">
          <label htmlFor="descripcion">Descripción:</label>
          <textarea
            className="input-textarea"
            name="descripcion"
            value={formData.descripcion}
            onChange={handleChange}
            placeholder='Ingrese descripcion del gasto'
          />
        </div>

        <div className="form-group">
          <label htmlFor="monto">Monto: *</label>
          <input
            className="input-monto"
            type="number"
            name="monto"
            value={formData.monto}
            onChange={handleChange}
            required // Agrega la propiedad required aquí
            placeholder='Ingrese monto del gasto'
          />
          {errors.monto && <p className="error-message">Este campo es requerido</p>}
        </div>

        <div className="form-group">
          <label htmlFor="moneda">Moneda: *</label>
          <select
            className="input-select"
            name="moneda"
            value={formData.moneda}
            onChange={handleChange}
            required // Agrega la propiedad required aquí
          >
            <option value="">Selecciona una moneda</option>
            <option value="reales">Reales</option>
            <option value="pesos_argentinos">Pesos Argentinos</option>
            <option value="dolares">Dólares</option>
            <option value="euros">Euros</option>
            <option value="libras">Libras</option>
          </select>
          {errors.moneda && <p className="error-message">Este campo es requerido</p>}
        </div>

        <div className="form-group">
          <label htmlFor="fecha">Fecha: *</label>
          <input
            className="input-fecha"
            type="date"
            name="fecha"
            value={formData.fecha}
            onChange={handleChange}
            required // Agrega la propiedad required aquí
          />
          {errors.fecha && <p className="error-message">Este campo es requerido</p>}
        </div>
          <button className="submit-buttongasto" type="submit">
            Añadir Gasto
          </button>
        </form>
      </div>
    </div>
  );
}

import React from 'react';
import '../styles/navBar.css';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import NotificationIcon from '@mui/icons-material/NotificationsNone';
import EventIcon from '@mui/icons-material/Event';

const ButtonAppBar = (props) => {
  return (
    <Box className="boxnav"sx={{ flexGrow: 1 }}>
      <AppBar position="static" className='bar' sx={{ backgroundColor: '#FFFFFF', position: 'fixed', top: 0, left: 0, right: 0 }}>
        <Toolbar>
          <Typography
            className="text"
            color="#162b4f"
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textShadow: '.2px .2px .2px #615b92',
            }}
          >
            {props.nombre}
          </Typography>
          <Link to="/novedades"><Button className="button_calendar" sx={{ color: '#162b4f'}}><NotificationIcon />Novedades</Button></Link>
          <Link to="/Calendario"><Button className="button_calendar" sx={{ color: '#162b4f' }}><EventIcon />Calendario</Button></Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default ButtonAppBar;

import { useState } from 'react';
import { createProperties } from '../api/propiedades.api';
import { createInventario } from '../api/inventario.api';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import '../styles/AddPropertiesForm.css';
import { toast } from 'react-toastify';

const servicios = ['Television', 'Cafetera', 'Microondas', 'FrigoBar', 'Internet', 'Caja Fuerte', 'Secador de pelo', 'Toallas', 'Aire Acondicionado', 'Ventilador','Cocina'];

export const Addproperties = ({ setShouldRefresh }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const [selectedServices, setSelectedServices] = useState([]);
  const [propiedadId, setPropiedadId] = useState(null); // Estado para almacenar el ID de la propiedad

  const handleServiceSelection = (service) => {
    if (selectedServices.includes(service)) {
      setSelectedServices(selectedServices.filter(s => s !== service));
    } else {
      setSelectedServices([...selectedServices, service]);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!propiedadId) {
      // Paso 1: Crear la propiedad
      const formData = new FormData();
      formData.append('nombre', data.nombre);
      formData.append('direccion', data.direccion);
      formData.append('provincia', data.provincia);
      formData.append('localidad', data.localidad);
      formData.append('codigoPostal', data.codigoPostal);
      formData.append('cantAmbientes', data.cantAmbientes);
      formData.append('descripcion', data.descripcion);
      formData.append('valorDiario', data.valorDiario);

      if (data.imagen && data.imagen[0]) {
        formData.append('imagen', data.imagen[0]);
      }

      try {
        const response = await createProperties(formData);
        const newPropiedadId = response.data.id;

        // Almacena el ID de la propiedad creada en el estado
        setPropiedadId(newPropiedadId);

        // Actualizar el paso para mostrar los servicios
        setSelectedServices([]);
        toast.success('Propiedad añadida correctamente');
      } catch (error) {
        toast.error('Error al añadir propiedad');
        console.error('Error al añadir propiedad:', error);
      }
    } else {
      // Paso 2: Crear registros de inventario para los servicios seleccionados
      try {
        await Promise.all(
          selectedServices.map(async (service) => {
            const inventarioData = {
              nombre: service,
              descripcion: data[`${service}Description`] || '',
              propiedad: propiedadId,
            };

            await createInventario(inventarioData);
          })
        );

        // Navegar a /propiedades después de crear los servicios
        navigate('/propiedades');
      } catch (error) {
        console.error('Error al añadir inventario:', error);
      }
    }
  });

  return (
    <div className="form-container">
      <form className="add-properties-form" onSubmit={onSubmit}>
        {propiedadId ? (
          // Paso 2: Selección de servicios
        <div>
          <p>Selecciona los servicios:</p>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {servicios.map((service) => (
              <li key={service} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    name={service}
                    checked={selectedServices.includes(service)}
                    onChange={() => handleServiceSelection(service)}
                  />
                  <span style={{ maxWidth: '250px',whiteSpace: 'nowrap' }}>
                    {service.charAt(0).toUpperCase() + service.slice(1)}
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Descripción del servicio"
                  {...register(`${service}Description`)}
                  style={{ marginLeft: '10px', padding: '5px', border: '1px solid #ccc', borderRadius: '3px' }}
                />
              </li>
            ))}
          </ul>
          <button className="submit-button" type="submit" onClick={() => setShouldRefresh(true)}>Finalizar Propiedad</button>
        </div>

        ) : (
          // Paso 1: Información de la propiedad
          <>
      <input type="text" placeholder='Nombre: *' {...register("nombre", { required: true })} />
        {errors.nombre && <span className="error-message">Este campo es requerido</span>}

        <input type="text" placeholder='Direccion: *' {...register("direccion", { required: true })} />
        {errors.direccion && <span className="error-message">Este campo es requerido</span>}

        <input type="text" placeholder='Provincia: *' {...register("provincia", { required: true })} />
        {errors.provincia && <span className="error-message">Este campo es requerido</span>}

        <input type="text" placeholder='Localidad: *' {...register("localidad", { required: true })} />
        {errors.localidad && <span className="error-message">Este campo es requerido</span>}

        <input type="text" placeholder='Codigo Postal: *' {...register("codigoPostal", { required: true })} />
        {errors.codigoPostal && <span className="error-message">Este campo es requerido</span>}

        <input type="text" placeholder='Cantidad de Ambientes: *' {...register("cantAmbientes", { required: true })} />
        {errors.cantAmbientes && <span className="error-message">Este campo es requerido</span>}

        <input type="number" placeholder='Valor Diario: *' {...register("valorDiario", { required: true })} />
        {errors.valorDiario && <span className="error-message">Este campo es requerido</span>}

        <textarea rows="3" placeholder='Descripcion del inmueble. Puede dejarse vacio' {...register("descripcion")}></textarea>

        <input type="file" accept="image/*" {...register("imagen")} />
            
            <button className="submit-button" type="submit">Siguiente</button>
          </>
        )}
      </form>
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { toast } from 'react-toastify';

Font.register({ family: 'Helvetica-Bold', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Helvetica-Bold.ttf' });

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 20,
  },
  section: {
    margin: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
  },
  table: {
    width: 520,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    flex: 1,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 6,
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
});

const GastosReport = ({ reportData }) => {
  let totalMonto = 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Informe de Gastos</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Propiedad</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Concepto</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Monto</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Fecha</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Descripcion</Text>
              </View>
            </View>
            {reportData.map((row, index) => {
              totalMonto += parseFloat(row.monto);
              return (
                <View style={styles.tableRow} key={index}>
                  <View style={styles.tableCell}>
                    <Text>{row.propiedad}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{row.concepto}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{row.monto}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{row.fecha}</Text>
                  </View>
                  <View style={styles.tableCell}>
                    <Text>{row.descripcion}</Text>
                  </View>
                </View>
              );
            })}
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
              <Text style={styles.bold}> Total: </Text>
              </View>
              <View style={styles.tableCell}></View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> {totalMonto.toFixed(2)} </Text>
              </View>
              <View style={styles.tableCell}>
              </View>
              <View style={styles.tableCell}></View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const generatePDFReport = (reportData) => {
  const newWindow = window.open('', '_blank');
  if (newWindow) {
    const pdfContent = (
      <PDFViewer width="100%" height="850px" margin="0px" padding="0px">
        <GastosReport reportData={reportData} />
      </PDFViewer>
    );
    ReactDOM.render(pdfContent, newWindow.document.body);
  } else {
    toast.error('No se pudo abrir una nueva pestaña para el informe');
  }
};

export default GastosReport;

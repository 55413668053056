// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_sidebar:hover{
    box-shadow: 0 0 0 1px #9595fa;
}
.sidebar-link{
    color: #fff; 
    text-decoration: none; 
}
.menu-options{
    background-color: #263A3f;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #fff;
    border-top: 1px solid #fff;
}

.sidebar-link2-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto; /* Empujará las opciones hacia la parte inferior */
  }
  
  /* Estilos para las opciones inferiores */
  .sidebar-link2 {
    color: #fff;
    text-decoration: none;
    width: 100%; /* Ocupa todo el ancho del sidebar */
    text-align: left; /* Alinea el texto a la izquierda */
    padding: 8px; /* Agrega un relleno para separar los elementos */
  }
  
  .create-user {
    width: 100%; /* Ocupa todo el ancho del sidebar */
    text-align: left; /* Alinea el texto a la izquierda */
    padding: 8px; /* Agrega un relleno para separar los elementos */
  }`, "",{"version":3,"sources":["webpack://./src/styles/sideBar.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,WAAW;IACX,qBAAqB;AACzB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,6BAA6B;IAC7B,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB,EAAE,kDAAkD;EACtE;;EAEA,yCAAyC;EACzC;IACE,WAAW;IACX,qBAAqB;IACrB,WAAW,EAAE,oCAAoC;IACjD,gBAAgB,EAAE,mCAAmC;IACrD,YAAY,EAAE,iDAAiD;EACjE;;EAEA;IACE,WAAW,EAAE,oCAAoC;IACjD,gBAAgB,EAAE,mCAAmC;IACrD,YAAY,EAAE,iDAAiD;EACjE","sourcesContent":[".button_sidebar:hover{\n    box-shadow: 0 0 0 1px #9595fa;\n}\n.sidebar-link{\n    color: #fff; \n    text-decoration: none; \n}\n.menu-options{\n    background-color: #263A3f;\n    display: flex;\n    justify-content: center;\n    border-bottom: 2px solid #fff;\n    border-top: 1px solid #fff;\n}\n\n.sidebar-link2-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    margin-top: auto; /* Empujará las opciones hacia la parte inferior */\n  }\n  \n  /* Estilos para las opciones inferiores */\n  .sidebar-link2 {\n    color: #fff;\n    text-decoration: none;\n    width: 100%; /* Ocupa todo el ancho del sidebar */\n    text-align: left; /* Alinea el texto a la izquierda */\n    padding: 8px; /* Agrega un relleno para separar los elementos */\n  }\n  \n  .create-user {\n    width: 100%; /* Ocupa todo el ancho del sidebar */\n    text-align: left; /* Alinea el texto a la izquierda */\n    padding: 8px; /* Agrega un relleno para separar los elementos */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

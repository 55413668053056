import axios from 'axios';
import config from '../config';

const clientesApi = axios.create({
    baseURL: `${config.baseUrl}inquilinos/api/v1/inquilinos`
  });

  export const getAllClients = () => {
    return clientesApi.get('/');
  };

  export const createClients = (formData) => {
    return clientesApi.post('/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Importante establecer el tipo de contenido adecuado
      }
    }); 
  };

  export const deleteClients = (id) => {
    return clientesApi.delete(`/${id}`);
  };

  export const updateClients = (id, clients ) => {
    return clientesApi.put(`/${id}/`, clients)
  };

  export const searchClients = (id) => {
    return clientesApi.get(`/${id}`);
  };

  export const getInquilinoById = (inquilinoId) => {
    return clientesApi.get(`/${inquilinoId}`)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });
  };

  export const updateClientPhoneNumber = (id, phoneNumber, telefonoNumero) => {
    const campoNumeroTelefono = `numero_telefonico_${telefonoNumero}`;
    const telefonoData = { [campoNumeroTelefono]: phoneNumber };
    return clientesApi.patch(`/${id}/`, telefonoData);
  };
  
import React, { useEffect, useState, useCallback} from 'react';
import ReactDOM from 'react-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { getAllAlquileres, updateAlquileres, deleteAlquileres} from '../api/alquileres.api';
import { getAllProperties } from '../api/propiedades.api';
import { getAllClients } from '../api/clientes.api';
import {getAcompananteByClientId, updateAcompanante, getAcompananteById  } from '../api/acompanantes.api';
import { useForm, Controller } from 'react-hook-form';
import '../styles/ListaAlquileres.css';
import { toast } from 'react-toastify';
import PDFGenerator from './MyDocument';
import { generatePDFAlquilerReport } from './AlquileresReport';
import { getValoresByPropiedad } from '../api/valores.api';
import { getRelationsByAlquilerId, deleteRelations, createRelations } from '../api/relAcompanantesAlquiler.api';

export const AlquileresList = () => {
  const [alquileres, setAlquileres] = useState([]);
  const [propertyData, setPropertyData] = useState({});
  const [clientData, setClientData] = useState({});
  const [editingAlquilerId, setEditingAlquilerId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [mostrarEdicionDialog, setMostrarEdicionDialog] = useState(false);
  const [alquilerToDelete, setAlquilerToDelete] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({
    searchQuery: "",
  });
  const [editingAlquilerData, setEditingAlquilerData] = useState({});
  const [filteredAlquileres, setFilteredAlquileres] = useState([]);
  const [expandedAlquilerId, setExpandedAlquilerId] = useState(null);
  const [acompanantes, setAcompanantes] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [mostrarAcompanantes, setMostrarAcompanantes] = useState(false);
  const [selectedClientAcompanantes, setSelectedClientAcompanantes] = useState([]);
  const [mostrarAcompanantesDialog, setMostrarAcompanantesDialog] = useState(false);
  const [acompanantesChanges, setAcompanantesChanges] = useState({});
  const { control, register, handleSubmit, formState: { errors }, watch ,reset} = useForm();
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedInquilino, setSelectedInquilino] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [alquilerMenu, setAlquilerMenu] = useState(null);
  const [alquilerCalculado, setAlquilerCalculado] = useState(0);
  const [relacionesAcompanantes, setRelacionesAcompanantes] = useState([]);
  const [relacionesFiltradas, setRelacionesFiltradas] = useState([]);
  const [bandera,setBandera] = useState(false);
  const fechaInicio = watch('fecha_inicio');
  const fechaFin = watch('fecha_fin');
  const seleccionCalculo = watch('calculoAlquiler');

  useEffect(() => {
    async function loadData() {
      try {

        const [alquileresRes, propertiesRes, clientsRes] = await Promise.all([
          getAllAlquileres(),
          getAllProperties(),
          getAllClients()
        ]);

        setAlquileres(alquileresRes.data);

        const propertyMap = {};
        const clientMap = {};

        propertiesRes.forEach(property => {
          propertyMap[property.id] = property;
        });

        clientsRes.data.forEach(client => {
          clientMap[client.id] = client;
        });

        setPropertyData(propertyMap);
        setClientData(clientMap);


      } catch (error) {
        console.error('Error loading data:', error);

      }
    }

    loadData();
  }, []);
  
  useEffect(() => {
    async function loadAcompanantes(clientId) {
      try {
        const acompanantesData = await getAcompananteByClientId(clientId);
        setAcompanantes(acompanantesData);
      } catch (error) {
        console.error('Error al obtener los acompañantes:', error);
        setAcompanantes([]);
      }
    }

    if (expandedAlquilerId && selectedClientId) {
      loadAcompanantes(selectedClientId);
    }
  }, [expandedAlquilerId, selectedClientId]);

  const toggleExpand = (alquilerId, clientId) => {
    if (expandedAlquilerId === alquilerId) {
      setExpandedAlquilerId(null);
      setSelectedClientId(null);
      setMostrarAcompanantes(false); // Oculta la lista de acompañantes al colapsar
    } else {
      setExpandedAlquilerId(alquilerId);
      setSelectedClientId(clientId);
      setMostrarAcompanantes(true); // Muestra la lista de acompañantes al expandir
    }
  };

  const handleEditarAcompanantes = async () => {
    try {
      const acompanantesData = await getAcompananteByClientId(selectedClientId);
      setSelectedClientAcompanantes(acompanantesData);
      setMostrarAcompanantesDialog(true); // Abre el diálogo de acompañantes
    } catch (error) {
      console.error('Error al obtener los acompañantes:', error);
    }
  };
  const [valorDiarioAlquiler, setValorDiarioAlquiler] = useState(
    selectedProperty ? selectedProperty.valorDiario : 0
  );

  const [valorPropiedadId, setValorPropiedadId] = useState(
    selectedProperty ? selectedProperty.id : 0
  );

  const calcularValorAlquilerConValorDiario = (fechaInicio, fechaFin, valorDiario) => {
    if (fechaInicio && fechaFin && valorDiario) {
      const fechaInicioObj = new Date(fechaInicio);
      const fechaFinObj = new Date(fechaFin);
      const diferenciaEnMilisegundos = fechaFinObj - fechaInicioObj;
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / 86400000);
      const valorCalculado = valorDiario * diferenciaEnDias;
      return valorCalculado;
    }
    return 0;
  };

  useEffect(() => {
    setBandera(false);
    if (seleccionCalculo == 2){
    const calcularValor = async () => {
      try {
        const valorCalculado = await calcularValorAlquiler(fechaInicio, fechaFin, valorPropiedadId, valorDiarioAlquiler);
        setAlquilerCalculado(valorCalculado);
      } catch (error) {
        console.error('Error al calcular el valor del alquiler en useEffect:', error);
      }
    };
  
    calcularValor();
  }
  else{
    const valorCalculado = calcularValorAlquilerConValorDiario(fechaInicio, fechaFin, valorDiarioAlquiler);
    setAlquilerCalculado(valorCalculado);
  }
  }, [fechaInicio, fechaFin, valorPropiedadId, valorDiarioAlquiler, seleccionCalculo]);

  const calcularValorAlquiler = async (fechaInicio, fechaFin, propiedadId, valorDiarioAlq) => {
    let ultValorDiario = 0;
    try {
      if (fechaInicio && fechaFin && propiedadId) {
        const fechaInicioObj = new Date(fechaInicio);
        const fechaFinObj = new Date(fechaFin);
  
      // Obtén todos los valores asociados a la propiedad seleccionada
      const valoresTodos = await getValoresByPropiedad(propiedadId);

      // Filtra solo los valores que corresponden a la propiedad específica
      const valores = valoresTodos.filter(valor => valor.propiedad == propiedadId);

  
        let valorTotal = 0;
  
        // Itera sobre cada día del periodo de alquiler
        for (let fechaActual = new Date(fechaInicioObj); fechaActual <= fechaFinObj; fechaActual.setDate(fechaActual.getDate() + 1)) {
          const fechaActualObj = new Date(fechaActual);
  
          // Busca el valor correspondiente a la fecha actual
          const valorDiario = valores.find(valor => {
            const valorDesde = new Date(valor.fecha_desde);
            const valorHasta = valor.fecha_hasta ? new Date(valor.fecha_hasta) : fechaFinObj;
          
            return fechaActualObj >= valorDesde && fechaActualObj <= valorHasta;
          });
          // Si se encuentra un valor, lo suma al total
          if (valorDiario) {
            valorTotal += parseFloat(valorDiario.valor_diario); // Usa el valorDiario del rango o el de la propiedad si no hay valorDiario específico
            ultValorDiario = parseFloat(valorDiario.valor_diario);
          }
          else{
            valorTotal +=parseFloat(valorDiarioAlq);
            ultValorDiario = parseFloat(valorDiarioAlq);
            setBandera(true);
          }
        }

        const valorCalculado = valorTotal || 0; // Usa el valorTotal calculado o el de la propiedad si no hay rangos que lo modifiquen
        const valorFinal = valorCalculado  - ultValorDiario;
        return valorFinal;
      }
      return 0;
    } catch (error) {
      console.error('Error al calcular el valor del alquiler:', error);
      return 0;
    }
  };

  const filterAlquileres = useCallback(() => {
    const filtered = alquileres.filter((alquiler) => {
      const property = propertyData[alquiler.propiedad];
      const client = clientData[alquiler.inquilino];
      const searchQuery = searchCriteria.searchQuery.toLowerCase();
      
  
      const matchesAddress =
        !searchQuery ||
        alquiler.id.toString().includes(searchQuery) ||
        (property && property.direccion && property.direccion.toLowerCase().includes(searchQuery)) ||
        (property && property.provincia && property.provincia.toLowerCase().includes(searchQuery)) ||
        (client && client.nombre && client.nombre.toLowerCase().includes(searchQuery)) ||
        (client && client.apellido && client.apellido.toLowerCase().includes(searchQuery));
      
      return matchesAddress;
    });
  
    setFilteredAlquileres(filtered);
  }, [alquileres, propertyData, clientData, searchCriteria.searchQuery]);
  
  useEffect(() => {
    filterAlquileres();
  }, [searchCriteria, alquileres, filterAlquileres]);


  const handlePrintContract = (alquiler) => {

    const newWindow = window.open('', '_blank');
    if (newWindow) {
      const pdfContent = (
        <PDFGenerator data={alquiler} />
      );
      ReactDOM.render(pdfContent, newWindow.document.body);
    } else {
      toast.error('No se pudo abrir una nueva pestaña para el contrato');
    }
  };

  const handleAcompananteChange = (acompananteId) => {
    setAcompanantesChanges((prevChanges) => ({
      ...prevChanges,
      [acompananteId]: !prevChanges[acompananteId],
    }));
  };

  const handleMenu = (alquilerId) => {
    const alquilerMenu = alquileres.find((alquiler) => alquiler.id === alquilerId);
    reset({
      propiedad: alquilerMenu.propiedad,
      inquilino: alquilerMenu.inquilino,
      fecha_inicio: alquilerMenu.fecha_inicio,
      fecha_fin: alquilerMenu.fecha_fin,
      valor: alquilerMenu.valor,
      check_in: alquilerMenu.check_in,
      check_out: alquilerMenu.check_out,
      moneda: alquilerMenu.moneda,
      metodo_pago: alquilerMenu.metodo_pago,
      tasa_limpieza: alquilerMenu.tasa_limpieza,
      senia: alquilerMenu.senia,
      valorDiario: alquilerMenu.valorDiario,
      primer_pago: alquilerMenu.primer_pago,
      segundo_pago: alquilerMenu.segundo_pago,
      tercer_pago: alquilerMenu.tercer_pago,
      // Asegúrate de agregar todos los campos necesarios
    });
    setAlquilerMenu(alquilerMenu);
  }

  const handleEditClick = (data) => {
    const alquilerToEdit = alquileres.find((alquiler) => alquiler.id === data.id);
    setEditingAlquilerData(alquilerToEdit);
    setEditingAlquilerId(data.id);
    setValorPropiedadId(alquilerToEdit.propiedad);
    setValorDiarioAlquiler(alquilerToEdit.valorDiario);

    // Reiniciar el formulario manualmente
    reset({
      propiedad: alquilerToEdit.propiedad,
      inquilino: alquilerToEdit.inquilino,
      fecha_inicio: alquilerToEdit.fecha_inicio,
      check_in: alquilerToEdit.check_in,
      fecha_fin: alquilerToEdit.fecha_fin,
      check_out: alquilerToEdit.check_out,
      valor: alquilerToEdit.valor,
      senia: alquilerToEdit.senia,
      metodo_pago: alquilerToEdit.metodo_pago,
      moneda: alquilerToEdit.moneda,
      valorDiario: alquilerToEdit.valorDiario,
      tasa_limpieza: alquilerToEdit.tasa_limpieza,
    });

    setMostrarEdicionDialog(true);
  };
  

  const handleDeleteClick = (alquilerId) => {
    setAlquilerToDelete(alquilerId);
    setIsDeleteDialogOpen(true);
  };

  const handleTerminarEdicion = async () => {
    try {
      const acompanantesId = [];
      // Filtra los acompañantes que pertenecen al inquilino seleccionado
      const acompanantesDelInquilino = acompanantes.filter(
        (acompanante) => acompanante.inquilino === selectedClientId
      );
      
      // Verifica si existen acompañantes antes de intentar actualizarlos
      if (acompanantesDelInquilino.length > 0) {
        const selectedAcompanantes = acompanantesChanges; // Usa el estado local de cambios
        const acompanantesToUpdate = [];
        let ban = 0;
  
        acompanantesDelInquilino.forEach((acompanante) => {
          const acompananteId = acompanante.id;
          const selectedAcompanante = selectedAcompanantes[acompananteId];
  
          if (selectedAcompanante !== undefined) {
            // Si el acompañante está en acompanantesChanges, usar su valor
            acompanantesToUpdate.push({
              id: acompananteId,
              activo: selectedAcompanante,
              inquilino: acompanante.inquilino,
              dni: acompanante.dni,
              cpf: acompanante.cpf,
              nombre: acompanante.nombre,
              apellido: acompanante.apellido,
              edad: acompanante.edad,
            });
          } else {
            // Si el acompañante no está en acompanantesChanges, desactivarlo
            acompanantesToUpdate.push({
              id: acompananteId,
              activo: false,
              inquilino: acompanante.inquilino,
              dni: acompanante.dni,
              cpf: acompanante.cpf,
              nombre: acompanante.nombre,
              apellido: acompanante.apellido,
              edad: acompanante.edad,
            });
          }
        });
  
        
        // Actualizar los acompañantes en la base de datos
        await Promise.all(
          acompanantesToUpdate.map(async (acompanante) => {
            try {
              if (acompanante.activo){
                acompanantesId[ban] = acompanante.id;
                ban += 1;
              }  
              await updateAcompanante(acompanante.id, {
                inquilino: acompanante.inquilino,
                dni: acompanante.dni,
                cpf: acompanante.cpf,
                nombre: acompanante.nombre,
                apellido: acompanante.apellido,
                edad: acompanante.edad,
                activo: acompanante.activo,
              });
              setSelectedClientId(null);
              setMostrarAcompanantesDialog(false);
              setMostrarAcompanantes(false);
            } catch (error) {
              console.error('Error al actualizar el acompañante:', error);
            }
          })
        );
        const formData = new FormData();
        for (let i = 0; i < acompanantesId.length; i++) {
          try {
            const acompananteId = parseInt(acompanantesId[i]);
            const response = await createRelations(formData, acompananteId, expandedAlquilerId);
          } catch (error) {
            console.error(`Error al crear relación para acompañante con ID ${acompanantesId[i]}`, error);
            // Puedes manejar el error de la manera que consideres adecuada
          }
        }
        setExpandedAlquilerId(null);
      }
  
      // Limpia el estado local de cambios
      setAcompanantesChanges({});
  
      // Otras acciones que desees realizar después de aplicar los cambios
    } catch (error) {
      console.error('Error al terminar la edición de acompañantes:', error);
    }
  };

  const handleDeleteAcompananteClick = async (acompananteEliminar) => {
    try {
      let relId = 0;
      for (let i = 0 ; i < relacionesFiltradas.length; i++){
        if (relacionesFiltradas[i].acompanantes == acompananteEliminar.id){
          relId = relacionesFiltradas[i].id
        }
      }
      await deleteRelations( parseInt(relId));
      setExpandedAlquilerId(null);
      filterAlquileres();
      toast.success('Acompañante eliminado correctamente');
    } catch (error) {
      toast.error('Ocurrió un error al eliminar acompañante');
    }
  };

  const onSubmitActualizar = async (data) => {
    let cantidad = 0;
    const fechaInicioObj = new Date(data.fecha_inicio);
    const fechaFinObj = new Date(data.fecha_fin);
    try {
      const valor = parseInt(data.valor) || 0; 
      const tasaLimpieza = parseInt(data.tasa_limpieza) || 0; 
      const senia = parseInt(data.senia) || 0; 
      const primerPago = parseInt(data.primer_pago) || 0; 
      const segundoPago = parseInt(data.segundo_pago) || 0; 
      const tercerPago = parseInt(data.tercer_pago) || 0; 
      const valorCalculado = valor + tasaLimpieza - senia - primerPago - segundoPago - tercerPago;
      data.valorAdeudado = valorCalculado;
      for (let fechaActual = new Date(fechaInicioObj); fechaActual <= fechaFinObj; fechaActual.setDate(fechaActual.getDate() + 1)) {
        cantidad += 1;
      }
      const valorCalculadoNuevo = (parseInt(data.valor) + parseInt(data.tasa_limpieza));
      data.valorCalculado = valorCalculadoNuevo;
      data.cantidad_dias = (cantidad - 1); // LE RESTO UN DIA PORQUE NO CUENTA LA FECHA FINAL
      data.valorPromedio = parseFloat(data.valor / data.cantidad_dias);
      data.fecha_contrato = editingAlquilerData.fecha_contrato;
      await updateAlquileres(editingAlquilerId, data);
      const alquilerid = editingAlquilerId; // Usar editingAlquilerId en lugar de data.id
      setAlquileres((prevAlquileres) =>
        prevAlquileres.map((alquiler) =>
          alquiler.id === alquilerid ? { ...data, id: alquilerid } : alquiler
        )
      );
  
      setEditingAlquilerId(null);
      setEditingAlquilerData({});
      filterAlquileres();
      setMostrarEdicionDialog(false);
      toast.success('Alquiler actualizado correctamente');
    } catch (error) {
      toast.error('Ocurrió un error al actualizar alquiler');
    }
  };

  const handleDeleteAlquiler = async () => {
    try {
      await deleteAlquileres(alquilerToDelete);
      setAlquileres(alquileres.filter(item => item.id !== alquilerToDelete));
      setIsDeleteDialogOpen(false);
      filterAlquileres();
      toast.success('Alquiler eliminado correctamente');
    } catch (error) {
      toast.error('Ocurrió un error al eliminar alquiler');
    }
  };

  const handleGeneratePDF = () => {
    // Aplicar filtros solo si se ha seleccionado una propiedad, inquilino o rango de fechas
    let filteredAlquiler = alquileres;
  
    if (selectedProperty) {
      filteredAlquiler = filteredAlquiler.filter((alquiler) => alquiler.propiedad == selectedProperty);
    }
  
    if (selectedInquilino) {
      filteredAlquiler = filteredAlquiler.filter((alquiler) => alquiler.inquilino == selectedInquilino);
    }
  
    if (startDate && endDate) {
      filteredAlquiler = filteredAlquiler.filter((alquiler) => {
        const alquilerDate = new Date(alquiler.fecha_inicio);
        const filterStartDate = new Date(startDate);
        const filterEndDate = new Date(endDate);
  
        return alquilerDate >= filterStartDate && alquilerDate <= filterEndDate;
      });
    }
  
    // Generar el informe PDF con los datos filtrados o sin filtrar
    const datosInforme = obtenerDatosInforme(filteredAlquiler);
    generatePDFAlquilerReport(datosInforme);
  };
  
  const obtenerDatosInforme = (alquileres) => {
    // Realiza cualquier manipulación necesaria en tus datos de alquileres
    const datosInforme = alquileres.map((alquiler) => ({
      propiedad: propertyData[alquiler.propiedad]?.direccion,
      nombre_inquilino: clientData[alquiler.inquilino]?.nombre,
      apellido_inquilino: clientData[alquiler.inquilino]?.apellido,
      fecha_inicio: alquiler.fecha_inicio,
      fecha_fin: alquiler.fecha_fin,
      valor: alquiler.valor,
      check_in: alquiler.check_in,
      check_out: alquiler.check_out,
      moneda: alquiler.moneda,
      metodo_pago: alquiler.metodo_pago,
      valorDiario: alquiler.valorDiario,
      tasa_limpieza: alquiler.tasa_limpieza,
      senia: alquiler.senia,
      valorAdeudado: alquiler.valorAdeudado,
      valorCalculado: alquiler.valorCalculado,
      valorPromedio: alquiler.valorPromedio,
      cantDias: alquiler.cantidad_dias,
      fecha_contrato: alquiler.fecha_contrato
    }));
    return datosInforme;
  };
  
  const cargarAcompanantes = async (alquilerId) => {
    try {
      const acompanantesRelacion = [];
      const response = await getRelationsByAlquilerId(alquilerId);
  
      // Verificar si la respuesta contiene datos
      if (response && response.data && response.data.length > 0) {
        // Filtrar las relaciones por alquilerId
        const relacionesfiltradas = response.data.filter(relacion => relacion.alquiler === alquilerId);
        setRelacionesFiltradas(relacionesfiltradas);
        // Iterar sobre las relaciones filtradas
        for (const relacion of relacionesfiltradas) {
              const acompananteInfo = await getAcompananteById( parseInt(relacion.acompanantes));
              // Añadir el acompañante al array acompanantesRelacion
              if (acompananteInfo) {
                acompanantesRelacion.push(acompananteInfo);
              }
        }
  
        // El array acompanantesRelacion ahora contiene la información de los acompañantes asociados al alquiler
        setRelacionesAcompanantes(acompanantesRelacion);
      }
    } catch (error) {
      console.error('Error al cargar acompañantes:', error);
    }
  };

  
  
  
  const onSubmitPagos = async (formData, alquilerToEditar, e) => {


    try {
      //e.preventDefault();
      // Parsea los valores a números y asegúrate de que no sean NaN
      const valor = parseFloat(alquilerToEditar.valor) || 0;
      const tasaLimpieza = parseFloat(alquilerToEditar.tasa_limpieza) || 0;
      const senia = parseFloat(alquilerToEditar.senia) || 0;
      const primerPago = parseFloat(formData.primer_pago) || 0;
      const segundoPago = parseFloat(formData.segundo_pago) || 0;
      const tercerPago = parseFloat(formData.tercer_pago) || 0;
      const valorCalculado = valor + tasaLimpieza - senia - primerPago - segundoPago - tercerPago;
      alquilerToEditar.valorAdeudado = valorCalculado;
      alquilerToEditar.primer_pago = formData.primer_pago;
      alquilerToEditar.segundo_pago = formData.segundo_pago;
      alquilerToEditar.tercer_pago = formData.tercer_pago;

      await updateAlquileres(alquilerToEditar.id, alquilerToEditar);
      const alquilerid = alquilerToEditar.id; 
      setAlquileres((prevAlquileres) =>
        prevAlquileres.map((alquiler) =>
          alquiler.id === alquilerid ? { ...alquilerToEditar, id: alquilerid } : alquiler
        )
      );
      
      toast.success('Pago de alquiler actualizado correctamente');
      setExpandedAlquilerId(null);
      filterAlquileres();
    } catch (error) {
      toast.error('El valor adeudado es negativo');
    }
  };
  

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Buscar alquiler"
          value={searchCriteria.searchQuery}
          onChange={(e) =>
            setSearchCriteria({ ...searchCriteria, searchQuery: e.target.value })
          }
          onBlur={filterAlquileres}
        />
      </div>
      <div className="containerAlq">
        <table>
          <thead>
            <tr className="listalq">
              <th className="listalqth">Contrato</th>
              <th className="listalqth" >Propiedad</th>
              <th className="listalqth">Inquilino</th>
              <th className="listalqth">Cantidad días</th>
              <th className="listalqth">Valor diario promedio</th>
              <th className="listalqth">Alquiler</th>
              <th className="listalqth">Tasa de Limpieza</th>
              <th className="listalqth">Valor total</th>
              <th className="listalqth">Seña</th>
              <th className="listalqth">Saldo pendiente</th>
              <th className="listalqth"></th>
            </tr>
          </thead>
          <tbody>
            {filteredAlquileres.length === 0 ? (
              <tr>
                <td colSpan="9">No hay alquileres registrados.</td>
              </tr>
            ) : (
              filteredAlquileres.map(alquiler => {
                const property = propertyData[alquiler.propiedad];
                const client = clientData[alquiler.inquilino];

                if (!property || !client) {
                  return null;
                }

                return (
                  <React.Fragment key={alquiler.id}>
                    <tr>
                      <td>{alquiler.id}</td>
                      <td>{property.direccion}</td>
                      <td>{`${client.nombre} ${client.apellido}`}</td>
                      <td>{alquiler.cantidad_dias}</td>
                      <td>{alquiler.valorPromedio}</td>
                      <td>{alquiler.valor}</td>
                      <td>{alquiler.tasa_limpieza}</td>
                      <td>{alquiler.valorCalculado}</td>
                      <td>{alquiler.senia}</td>
                      <td>{alquiler.valorAdeudado}</td>
                      <td className="action-buttonsAlq">
                        <Button  className="botonesLista" onClick={() => handleEditClick(alquiler)}>
                          <EditIcon />
                        </Button>
                        <Button   className="botonesLista" onClick={() => handleDeleteClick(alquiler.id)}>
                          <DeleteIcon />
                        </Button>
                        <Button className="botonesLista" onClick={() => handlePrintContract(alquiler)}>
                          <PrintIcon />
                        </Button>
                        <button className="botonesLista" onClick={() => {
                            toggleExpand(alquiler.id, alquiler.inquilino);
                            handleMenu(alquiler.id);
                            cargarAcompanantes(alquiler.id);
                          }}>
                          {expandedAlquilerId === alquiler.id ? '-' : '+'}
                        </button>
                      </td>
                    </tr>
                    {expandedAlquilerId === alquiler.id && (
                      <tr>
                        <td colSpan="11">
                          {mostrarAcompanantes && (
                            <div>
                              <h4 className="duracion">DURACION DEL CONTRATO | Desde: {alquiler.fecha_inicio}    Hasta: {alquiler.fecha_fin}</h4>
                              {alquiler.metodo_pago === '3_pagos' && (
                                  <p><b>METODO DE PAGO: </b> 3 pagos</p>
                              )}
                              {alquiler.metodo_pago === '2_pagos' && (
                                  <p><b>METODO DE PAGO: </b>2 pagos</p>
                              )}
                              {(alquiler.metodo_pago == 'transferencia' || alquiler.metodo_pago == 'contado') && (
                                  <p><b>METODO DE PAGO: </b>{alquiler.metodo_pago}</p>
                              )}
                              { (alquiler.metodo_pago == 'transferencia' || alquiler.metodo_pago == 'contado' ) && 
                                <div>
                                    <form  onSubmit={handleSubmit((formData) => onSubmitPagos(formData, alquiler))}>
                                    <input type="hidden" {...register("propiedad", { value: alquiler.propiedad })} />
                                    <input type="hidden" {...register("inquilino", { value: alquiler.inquilino })} />
                                    <input type="hidden" {...register("fecha_inicio", { value: alquiler.fecha_inicio })} />
                                    <input type="hidden" {...register("fecha_fin", { value: alquiler.fecha_fin })} />
                                    <input type="hidden" {...register("valor", { min: 0, value: alquiler.valor })} />
                                    <input type="hidden" {...register("check_in", { value: alquiler.check_in })} />
                                    <input type="hidden" {...register("check_out", { value: alquiler.check_out })} />
                                    <input type="hidden" {...register("valorCalculado", { min: 0, value: alquiler.valorCalculado })} />
                                    <input type="hidden" {...register("valorPromedio", { min: 0, value: alquiler.valorPromedio })} />
                                    <input type="hidden" {...register("cantidad_dias", { min: 0, value: alquiler.cantidad_dias })} />
                                    <input type="hidden" {...register("moneda", { value: alquiler.moneda })} />
                                    <input type="hidden" {...register("metodo_pago", { value: alquiler.metodo_pago })} />
                                    <input type="hidden" {...register("tasa_limpieza", { min: 0, value: alquiler.tasa_limpieza })} />
                                    <input type="hidden" {...register("senia", { min: 0, value: alquiler.senia })} />
                                    <input type="hidden" {...register("valorDiario", { value: alquiler.valorDiario })} />
                                      
                                    <label>Pago:</label>
                                    <input type="number" step="0.01" defaultValue={alquilerMenu.primer_pago} {...register('primer_pago')} />
                                    <br></br>
                                    <br></br>
                                    <button type="submit">Actualizar pagos</button>
                                    </form>
                                </div>
                              }
                              {alquiler.metodo_pago == '2_pagos' && (
                                <div>
                                  <form onSubmit={handleSubmit((formData) => onSubmitPagos(formData, alquiler))}>
                                  <input type="hidden" {...register("propiedad", { value: alquiler.propiedad })} />
                                  <input type="hidden" {...register("inquilino", { value: alquiler.inquilino })} />
                                  <input type="hidden" {...register("fecha_inicio", { value: alquiler.fecha_inicio })} />
                                  <input type="hidden" {...register("fecha_fin", { value: alquiler.fecha_fin })} />
                                  <input type="hidden" {...register("valor", { min: 0, value: alquiler.valor })} />
                                  <input type="hidden" {...register("check_in", { value: alquiler.check_in })} />
                                  <input type="hidden" {...register("check_out", { value: alquiler.check_out })} />
                                  <input type="hidden" {...register("moneda", { value: alquiler.moneda })} />
                                  <input type="hidden" {...register("valorCalculado", { min: 0, value: alquiler.valorCalculado })} />
                                  <input type="hidden" {...register("valorPromedio", { min: 0, value: alquiler.valorPromedio })} />
                                  <input type="hidden" {...register("cantidad_dias", { min: 0, value: alquiler.cantidad_dias })} />
                                  <input type="hidden" {...register("metodo_pago", { value: alquiler.metodo_pago })} />
                                  <input type="hidden" {...register("tasa_limpieza", { min: 0, value: alquiler.tasa_limpieza })} />
                                  <input type="hidden" {...register("senia", { min: 0, value: alquiler.senia })} />
                                  <input type="hidden" {...register("valorDiario", { value: alquiler.valorDiario })} />
                                  <label>Primer Pago:</label>
                                  <input type="number" step="0.01" defaultValue={alquilerMenu.primer_pago} {...register('primer_pago')} />
                                  <br></br>
                                  <label>Segundo Pago:</label>
                                  <input type="number" step="0.01"  defaultValue={alquilerMenu.segundo_pago} {...register('segundo_pago')} />
                                  <br></br>
                                  <button type="submit">Actualizar pagos</button>
                                  <br></br>
                                  <br></br>
                                  </form>
                                </div>
                              )}
                              {alquiler.metodo_pago === '3_pagos' && (
                                <div>
                                  <form onSubmit={handleSubmit((formData) => onSubmitPagos(formData, alquiler))}>
                                  <input type="hidden" {...register("propiedad", { value: alquiler.propiedad })} />
                                  <input type="hidden" {...register("inquilino", { value: alquiler.inquilino })} />
                                  <input type="hidden" {...register("fecha_inicio", { value: alquiler.fecha_inicio })} />
                                  <input type="hidden" {...register("fecha_fin", { value: alquiler.fecha_fin })} />
                                  <input type="hidden" {...register("valor", { min: 0, value: alquiler.valor })} />
                                  <input type="hidden" {...register("check_in", { value: alquiler.check_in })} />
                                  <input type="hidden" {...register("check_out", { value: alquiler.check_out })} />
                                  <input type="hidden" {...register("moneda", { value: alquiler.moneda })} />
                                  <input type="hidden" {...register("metodo_pago", { value: alquiler.metodo_pago })} />
                                  <input type="hidden" {...register("valorCalculado", { min: 0, value: alquiler.valorCalculado })} />
                                  <input type="hidden" {...register("valorPromedio", { min: 0, value: alquiler.valorPromedio })} />
                                  <input type="hidden" {...register("cantidad_dias", { min: 0, value: alquiler.cantidad_dias })} />
                                  <input type="hidden" {...register("senia", { min: 0, value: alquiler.senia })} />
                                  <input type="hidden" {...register("valorDiario", { value: alquiler.valorDiario })} />
                                  <label>Primer Pago:</label>
                                  <input type="number" step="0.01" defaultValue={alquilerMenu.primer_pago} {...register('primer_pago')} />
                                  <br></br>
                                  <label>Segundo Pago:</label>
                                  <input type="number" step="0.01" defaultValue={alquilerMenu.segundo_pago} {...register('segundo_pago')} />
                                  <br></br>
                                  <label>Tercer Pago:</label>
                                  <input type="number" step="0.01" defaultValue={alquilerMenu.tercer_pago} {...register('tercer_pago')} />
                                  <br></br>
                                  <button type="submit">Actualizar pagos</button>
                                  <br></br>
                                  <br></br>
                                  </form>
                                </div>
                              )}
                              {acompanantes.length > 0 ? (
                                <div>
                                  <h4 style={{marginTop:'20px'}} className="listtAcompanantes"><b>Lista de acompañantes de {`${client.nombre} ${client.apellido}`}</b></h4>
                                  {relacionesAcompanantes
                                    .map((acompanante) => (
                                      <div key={acompanante.id}>
                                        - {acompanante.nombre} {acompanante.apellido} 
                                        <Button  onClick={() => handleDeleteAcompananteClick(acompanante)}>
                                          <DeleteIcon />
                                        </Button>
                                      </div>
                                    ))}
                                  <button onClick={handleEditarAcompanantes}>
                                    Editar Acompañantes
                                  </button>
                                </div>
                              ) : (
                                <p>No posee acompañantes activos.</p>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
        </table>
        <Dialog
          open={mostrarEdicionDialog}
          onClose={() => {setMostrarEdicionDialog(false)}}
          aria-labelledby="form-dialog-title"
          style={{ width: '100%', height: '100%' }}
        >
          <DialogTitle id="form-dialog-title">Editar Alquiler</DialogTitle>
          <DialogContent>
            <div className="form-wrapper edit">
            <form onSubmit={handleSubmit(onSubmitActualizar)}>
          <div className="form-group">
            <label htmlFor="propiedad">Propiedad</label>
            <select defaultValue={editingAlquilerData.propiedad} {...register("propiedad", { required: true })}>
              {Object.keys(propertyData).map(propertyId => (
                <option key={propertyId} value={propertyId}>
                  {propertyData[propertyId].direccion} - {propertyData[propertyId].nombre}
                </option>
              ))}
            </select>
            {errors.propiedad && <p className="error-message">Este campo es requerido</p>}
          </div>
          <div className="form-group">
            <label htmlFor="inquilino">Inquilino</label>
            <select defaultValue={editingAlquilerData.inquilino} {...register("inquilino", { required: true })}>
              {Object.keys(clientData).map(clientId => (
                <option key={clientId} value={clientId}>
                  {clientData[clientId].nombre} {clientData[clientId].apellido}
                </option>
              ))}
            </select>
            {errors.inquilino && <p className="error-message">Este campo es requerido</p>}
          </div>

          <div className="form-groupfec">
          <div className="columnFec">
            <label htmlFor="fecha_inicio">Fecha de Inicio</label><br></br>
            <input type="date" defaultValue={editingAlquilerData.fecha_inicio}{...register("fecha_inicio", { required: true })} />
            {errors.fecha_inicio && <p className="error-message">Este campo es requerido</p>}
          </div>
          <div className="columnFec">
            <label htmlFor="check_in">Check-in</label><br/>
            <input type="time" defaultValue={editingAlquilerData.check_in}{...register("check_in")} />
          </div>
          </div>

          <div className="form-groupfec">
          <div className="columnFec">
            <label htmlFor="fecha_fin">Fecha de Fin</label><br></br>
            <input type="date" defaultValue={editingAlquilerData.fecha_fin}{...register("fecha_fin", { required: true })} />
            {errors.fecha_fin && <p className="error-message">Este campo es requerido</p>}
          </div>
          <div className="columnFec">
            <label htmlFor="check_out">Check-out</label><br/>
            <input type="time" defaultValue={editingAlquilerData.check_out} {...register("check_out")} />
          </div>
          </div>
          <div className="form-group-horizontal">
          <div className="form-group columnas alqedit">
            <label htmlFor="valorDiario">Valor Diario (por defecto)</label>
            <input
              type="number"
              step="0.01"
              {...register("valorDiario", { required: true, min: 0 })}
              defaultValue={editingAlquilerData.valorDiario}
              onChange={(e) => setValorDiarioAlquiler(e.target.value)}
            />
           </div>
           <div className="form-group columnas alqedit">
            <label htmlFor="tasa_limpieza">Tasa de Limpieza</label>
            <input
              type="number"
              step="0.01"
              {...register("tasa_limpieza", { min: 0 })}
              placeholder="Tasa de Limpieza"
              defaultValue={editingAlquilerData.tasa_limpieza}
            />
          </div>
          </div>

          <div>
            <Controller
              name="calculoAlquiler"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value="1">Valor diario (por defecto)</option>
                  <option value="2">Valor por rangos</option>
                </select>
              )}
            />
          </div>

          <div className="form-group-horizontal">
          <div className="form-group columnas alqedit">
            <label htmlFor="valor">Valor de Alquiler</label>
            <input type="number" step="0.01" defaultValue={editingAlquilerData.valor} {...register("valor", { required: true, min: 0 })} />
            {errors.valor && <p className="error-message">Este campo es requerido</p>}
            <p>Valor recomendado: ${alquilerCalculado || ''}</p>
            {bandera && (
              <div>
              <p style={{fontSize:'10px',color:'red', marginBottom:'5px'}}>Se ha utilizado el valor diario por defecto de la propiedad</p>
              </div>
            )}
          </div>
            {bandera ? (
              <div className="form-group columnas senia" style={{marginBottom:'90px', marginRight:'50px'}}>
                <label htmlFor="senia">Seña</label>
                <input
                  type="number"
                  step="0.01"
                  defaultValue={editingAlquilerData.senia}
                  {...register("senia", { min: 0 })}
                  placeholder="Monto de la seña"
                />
              </div>
            ) : (
              <div className="form-group columnas senia" style={{marginBottom:'65px', marginRight:'50px'}}>
                <label htmlFor="senia">Seña</label>
                <input
                  type="number"
                  step="0.01"
                  defaultValue={editingAlquilerData.senia}
                  {...register("senia", { min: 0 })}
                  placeholder="Monto de la seña"
                />
              </div>
            )}
          </div>
          <div className="form-group-horizontal">
          <div className="form-group columnas">
            <label htmlFor="metodo_pago">Método de Pago</label>
            <select  defaultValue={editingAlquilerData.metodo_pago} {...register("metodo_pago", { required: true })}>
              <option value="contado">Contado</option>
              <option value="transferencia">Transferencia</option>
              <option value="2_pagos">2 Pagos</option>
              <option value="3_pagos">3 Pagos</option>
            </select>
            {errors.metodo_pago && <p className="error-message">Este campo es requerido</p>}
          </div>
          <div className="form-group columnas">
            <label htmlFor="moneda">Moneda</label>
            <select defaultValue={editingAlquilerData.moneda} {...register("moneda")}>
            <option value="reales">Reales</option>
            <option value="pesos_argentinos">Pesos Argentinos</option>  
            <option value="dolares">Dólares</option>  
            <option value="euros">Euros</option>
            <option value="libras">Libras</option>
            </select>
          </div>
        </div>

          <div className="form-group">
            <button type="submit">Actualizar Alquiler</button>
          </div>
          </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() =>{setMostrarEdicionDialog(false);} }color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirmar Eliminación</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Estás seguro de que deseas eliminar este alquiler?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setIsDeleteDialogOpen(false); filterAlquileres(); }} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setIsDeleteDialogOpen(false);
                handleDeleteAlquiler();
              }}
              color="primary"
              autoFocus
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
        open={mostrarAcompanantesDialog}
        onClose={() => setMostrarAcompanantesDialog(false)}
        aria-labelledby="acompanantes-dialog-title"
        aria-describedby="acompanantes-dialog-description"
      >
        <DialogTitle id="acompanantes-dialog-title">Acompañantes del Inquilino</DialogTitle>
        <DialogContent>
          {selectedClientAcompanantes.length > 0 ? (
            <div>
              {selectedClientAcompanantes
                .filter((acompanante) => acompanante.inquilino === selectedClientId)
                .map((acompanante) => (
                  <div key={acompanante.id}>
                  <input
                    type="checkbox"
                    onChange={() => handleAcompananteChange(acompanante.id)}
                  />
                  <label>{acompanante.nombre} {acompanante.apellido}</label>
                </div>
                ))}
                <button onClick={handleTerminarEdicion}>Terminar Edición</button>
            </div>
          ) : (
            <p>No hay acompañantes registrados para este inquilino.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMostrarAcompanantesDialog(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      </div>
            {/* Selector de propiedad */}
            <select
        value={selectedProperty}
        onChange={(e) => setSelectedProperty(e.target.value)}
        className="informeAlq"
      >
        <option value="">Todas las propiedades</option>
        {Object.keys(propertyData).map((propertyId) => (
          <option key={propertyId} value={propertyId}>
            {propertyData[propertyId]?.direccion}
          </option>
        ))}
      </select>

      {/* Selector de inquilino */}
      <select
        value={selectedInquilino}
        onChange={(e) => setSelectedInquilino(e.target.value)}
        className="informeAlq"
      >
        <option value="">Todos los pasajeros</option>
        {Object.keys(clientData).map((clientId) => (
          <option key={clientId} value={clientId}>
            {clientData[clientId]?.nombre} {clientData[clientId]?.apellido}
          </option>
        ))}
      </select>

      {/* Selector de rango de fechas */}
      Desde:
      <input
        type="date"
        placeholder="Fecha inicial"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="informeAlq"
      />
      Hasta:
      <input
        type="date"
        placeholder="Fecha final"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="informeAlq"
      />

      <button className="submit-button buttonInformeAlq" onClick={handleGeneratePDF}>
        Generar Informe PDF
      </button>
    </div>
  );
};

import React, { useState} from 'react';
import { createClients } from '../api/clientes.api';
import { createAcompanante} from '../api/acompanantes.api';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import '../styles/AddClients.css';

export const AddClients = ({ setShouldRefresh }) => {
  const { register, handleSubmit: handleSubmitCliente, formState: { errors } } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [acompanantes, setAcompanantes] = useState([]);
  const [acompananteData, setAcompananteData] = useState({ nombre: '', apellido: '', dni: '', cpf: '', edad: '' });
  const [step, setStep] = useState(1); // Controla el paso del formulario
  const [pasajeroId, setPasajeroId] = useState(null); // Para almacenar el ID del pasajero creado en el paso 1
  const [telefonoInputs, setTelefonoInputs] = useState([1]);


  const onSubmitCliente = async (data, event) => {
    event.preventDefault();

    // Verificar si el pasajero ya existe
    if (!pasajeroId) {
      const formData = new FormData();
      formData.append('nombre', data.nombre);
      formData.append('apellido', data.apellido);
      formData.append('correo', data.correo);
      formData.append('numero_telefonico_1', data.numero_telefonico_1);
      formData.append('documento', data.documento);
      formData.append('direccion', data.direccion);
      formData.append('cpf', data.cpf);

      // Verificar y agregar números de teléfono adicionales
      telefonoInputs.forEach((index) => {
        const numeroTelefonoAdicional = data[`numero_telefonico_${index}`];
        if (numeroTelefonoAdicional) {
          formData.append(`numero_telefonico_${index}`, numeroTelefonoAdicional);
        }
      });

      try {
        const response = await createClients(formData);
        if (response.status === 201) {
          toast.success('Pasajero añadido correctamente');
          setPasajeroId(response.data.id); // Guardar el ID del pasajero
          setStep(2); // Ir al paso de agregar acompañantes
        }
      } catch (error) {
        // Manejar errores aquí
        console.error(error);
      }
    } else {
      // Si el pasajero ya existe, simplemente pasar al siguiente paso
      setStep(2);
    }
  };

  const addTelefonoInput = () => {
    if (telefonoInputs.length < 4) {
      setTelefonoInputs([...telefonoInputs, telefonoInputs.length + 1]);
    }
  };


  const onSubmitAcompanante = async (event) => {
    event.preventDefault();
    if (acompananteData.nombre && acompananteData.apellido && acompananteData.edad) {
      try {
        // Agrega el ID del pasajero principal al objeto de acompañanteData
        acompananteData.inquilino = pasajeroId; // Usar el ID del pasajero principal
        const response = await createAcompanante(acompananteData);
        if (response.status === 201) {
          toast.success('Acompañante añadido correctamente');
          const nuevoAcompanante = { ...acompananteData, id: response.data.id };
          setAcompanantes([...acompanantes, nuevoAcompanante]);
          resetAcompananteForm(); // Reiniciar el formulario de acompañante después de agregarlo
        }
      } catch (error) {
        toast.error('Error al añadir acompañante');
        console.error(error);
      }
    } else {
      setErrorMessage("Por favor, complete todos los campos obligatorios (*) del acompañante.");
    }
  };

  const resetAcompananteForm = () => {
    // Limpia el formulario de acompañante
    setAcompananteData({ nombre: '', apellido: '', dni: '', cpf: '', edad: '' });
    setErrorMessage('');
  };

  return (
    <div className="form-container">
      <form className="add-properties-form" onSubmit={handleSubmitCliente(onSubmitCliente)}>
        {step === 1 && (
          <>
            {/* Campos para el inquilino principal */}
            <input type="text" placeholder="Nombre: *" {...register("nombre", { required: true })} />
            {errors.nombre && <span className="error-message">Este campo es requerido</span>}
            
            <input type="text" placeholder="Apellido: *" {...register("apellido", { required: true })} />
            {errors.apellido && <span className="error-message">Este campo es requerido</span>}

            <input type="text" placeholder="Direccion: *" {...register("direccion", { required: true })} />
            {errors.direccion && <span className="error-message">Este campo es requerido</span>}
            
            <input type="text" placeholder="Documento / RG:" {...register("documento")} />
            
            <input type="text" placeholder="CPF:" {...register("cpf")} />
            
            {/* Campos para el número de teléfono */}
            {telefonoInputs.map((index) => (
              <div key={index}>
                <input type="text" placeholder={`Número de Teléfono ${index}: *`} {...register(`numero_telefonico_${index}`, { required: true })} />
                {errors[`numero_telefonico_${index}`] && <span className="error-message">Este campo es requerido</span>}
              </div>
            ))}
            <button type="button" onClick={addTelefonoInput} style={{ padding: '3px' }}>Agregar telefono adicional</button>
            
            <input type="text" placeholder="Correo: *" {...register("correo", { required: true })} />
            {errors.correo && <span className="error-message">Este campo es requerido</span>}
            
            <button type="submit">Siguiente</button>
          </>
        )}

        {step === 2 && (
          <>
            <p>¿Tiene acompañantes?</p>
            <button onClick={() => setStep(3)}>Sí</button>
            <button onClick={() => setShouldRefresh(true)}>No</button>
          </>
        )}

        {step === 3 && (
          <>
            <div className="acompanantes-form">
              {/* Campos para el acompañante */}
              <input type="text" placeholder="Nombre  del Acompañante: *" value={acompananteData.nombre} onChange={(e) => setAcompananteData({ ...acompananteData, nombre: e.target.value })} />
              <input type="text" placeholder="Apellido del Acompañante: *" value={acompananteData.apellido} onChange={(e) => setAcompananteData({ ...acompananteData, apellido: e.target.value })} />
              <input type="text" placeholder="DNI / RG del Acompañante:" value={acompananteData.dni || ''} onChange={(e) => setAcompananteData({ ...acompananteData, dni: e.target.value })} />
              <input type="text" placeholder="CPF del Acompañante:" value={acompananteData.cpf || ''} onChange={(e) => setAcompananteData({ ...acompananteData, cpf: e.target.value })} />
              <input type="text" placeholder="Edad del Acompañante: *" value={acompananteData.edad} onChange={(e) => setAcompananteData({ ...acompananteData, edad: e.target.value })} />
              {/* Agregar otros campos para el acompañante aquí */}
              <button onClick={onSubmitAcompanante}>Agregar Acompañante</button>
            </div>
            
            <div className="acompanantes-list">
              <p style={{ marginBottom: '0px' }}>Lista de acompañantes:</p>
              {acompanantes.map((acompanante, index) => (
                <div>
                <div key={index} className="acompanante-item">
                  NOMBRE: {acompanante.nombre} {acompanante.apellido} (DNI/RG: {acompanante.dni} / CPF: {acompanante.cpf} / EDAD: {acompanante.edad})
                </div>
                </div>
              ))}
            </div>
            <div className="acompanantes-form"><button type="button" onClick={() => setShouldRefresh(true)} >Finalizar</button></div>
            
          </>
        )}
      </form>

      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};





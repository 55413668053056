import axios from 'axios';
import config from '../config'; 

const relAcompananteAlquilerApi = axios.create({
    baseURL: `${config.baseUrl}relAcompananteAlquiler/api/v1/relAcompananteAlquiler`
  });

  export const getAllRelations = () => {
    return relAcompananteAlquilerApi.get('/');
  };


  export const createRelations = (formData, acompanantesId, alquilerId) => {
    formData.append('acompanantes', JSON.stringify(acompanantesId));
    formData.append('alquiler', alquilerId);
  
    return relAcompananteAlquilerApi.post('/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }); 
  };
  export const deleteRelations = (id) => {
    return relAcompananteAlquilerApi.delete(`/${id}`);
  };

export const updateRelations = async (relAcompananteAlquilerApiId, relAcompananteAlquilerApiData) => {
  return relAcompananteAlquilerApi.put(`/${relAcompananteAlquilerApiId}/`, relAcompananteAlquilerApiData)
};

export const getRelationsByAlquilerId = (alquilerId) => {
    const response = relAcompananteAlquilerApi.get(`/?alquiler=${alquilerId}`);
    return response;

  };
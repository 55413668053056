import axios from 'axios';
import config from '../config'; 

const apiUrl = `${config.baseUrl}acompanante/api/v1/acompanante/`; // Reemplaza esto con la URL correcta de tu API

// Obtener todos los acompañantes de un inquilino específico
export const getAcompanantes = (inquilinoId) => {
  const url = `${apiUrl}?inquilino=${inquilinoId}`;
  return axios.get(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// Crear un nuevo acompañante
export const createAcompanante = (acompananteData) => {
  return axios.post(apiUrl, acompananteData)
};

// Actualizar un acompañante existente
export const updateAcompanante = (acompananteId, acompananteData) => {
  const url = `${apiUrl}${acompananteId}/`;
  return axios.put(url, acompananteData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// Eliminar un acompañante
export const deleteAcompanante = (acompananteId) => {
  const url = `${apiUrl}${acompananteId}/`;
  return axios.delete(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getAcompananteById = async (acompananteId) => {
  try {
    const response = await axios.get(`${apiUrl}${acompananteId}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAcompananteByClientId = async (clientId) => {
  try {
    const response = await axios.get(`${apiUrl}?inquilino=${clientId}`);
    return response.data;
  } catch (error) {
    throw error;
  }


};
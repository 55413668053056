// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  margin-left: 200px; /* Considera el ancho del sidebar */
  background-color: white;
  flex-wrap: wrap;
  width: 100%;
}

/* Media query para pantallas más pequeñas */
@media only screen and (max-width: 768px) {
  .centered-container {
    margin-left: 0; /* Elimina el margen izquierdo en pantallas más pequeñas */
    width: -moz-fit-content;
    width: fit-content;
  }
}

.items_center {
  margin-left: 20px;
  margin-top: 0px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-right: 20px; 
  margin-left: 20px; 
  border-bottom: 1px solid #ccc; 
  padding-bottom: 5px; 
}

.search-input {
  padding: 6px;
  border: none; 
  outline: none;
  transition: border-color 0.3s ease-in-out;
  width: 100%;
  max-width: 300px; 
  background-color: transparent; 
}

.search-input:focus {
  border-color: transparent; 
}
`, "",{"version":3,"sources":["webpack://./src/styles/LabTabs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,kBAAkB,EAAE,mCAAmC;EACvD,uBAAuB;EACvB,eAAe;EACf,WAAW;AACb;;AAEA,4CAA4C;AAC5C;EACE;IACE,cAAc,EAAE,0DAA0D;IAC1E,uBAAkB;IAAlB,kBAAkB;EACpB;AACF;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,yCAAyC;EACzC,WAAW;EACX,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".centered-container {\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  margin-left: 200px; /* Considera el ancho del sidebar */\n  background-color: white;\n  flex-wrap: wrap;\n  width: 100%;\n}\n\n/* Media query para pantallas más pequeñas */\n@media only screen and (max-width: 768px) {\n  .centered-container {\n    margin-left: 0; /* Elimina el margen izquierdo en pantallas más pequeñas */\n    width: fit-content;\n  }\n}\n\n.items_center {\n  margin-left: 20px;\n  margin-top: 0px;\n}\n\n.search-container {\n  display: flex;\n  align-items: center;\n  margin-right: 20px; \n  margin-left: 20px; \n  border-bottom: 1px solid #ccc; \n  padding-bottom: 5px; \n}\n\n.search-input {\n  padding: 6px;\n  border: none; \n  outline: none;\n  transition: border-color 0.3s ease-in-out;\n  width: 100%;\n  max-width: 300px; \n  background-color: transparent; \n}\n\n.search-input:focus {\n  border-color: transparent; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

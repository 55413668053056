import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';


function Init() {
  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#1d335a' }}>
      <Container maxWidth="xs" style={{ paddingTop: '80px', backgroundColor: 'transparent' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" component="div" gutterBottom style={{textAlign: 'center'}}>
            Usuario Actualizado Correctamente
          </Typography>
            <Box mt={2}>
                <Link to="/login">
              <Button
                variant="contained"
                style={{ backgroundColor: '#1d335a', color: 'white' }}
                fullWidth
              >
                Ir a iniciar sesion
              </Button>
              </Link>
            </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default Init;

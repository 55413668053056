import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.baseUrl}token/`, {
        username,
        password,
      });

      const { access } = response.data;

      if (access) {
        // Almacenar el token JWT en el almacenamiento local
        localStorage.setItem('token', access);

        // Llamar a la función onLogin para gestionar el estado de autenticación en App.js
        onLogin();

        // Redirigir a la página principal o realizar otras acciones después del inicio de sesión
        navigate('/calendario');
        
        // Opcional: mostrar un mensaje de éxito
        toast.success('Sesión iniciada correctamente');
      } else {
        console.error('Token JWT no recibido en la respuesta.');
        toast.error('Error al iniciar sesión');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      toast.error('Error al iniciar sesión');
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#1d335a' }}>
      <Container maxWidth="xs" style={{ paddingTop: '80px', backgroundColor: 'transparent' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" component="div" gutterBottom>
            Iniciar sesión
          </Typography>
          <form>
            <TextField
              label="Nombre de usuario"
              fullWidth
              margin="normal"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              type="password"
              label="Contraseña"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box mt={2}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#1d335a', color: 'white' }}
                fullWidth
                onClick={handleLogin}
              >
                Iniciar sesión
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default Login;

import React from 'react'
import ButtonAppBar from '../../components/navBar'
import Sidebar from '../../components/sideBar';
import LabTabs from '../../components/PropTabItems';


const GestorPropiedades = () => {
  return (
    <div>
    <ButtonAppBar nombre='Propiedades' />
      <div>
        <Sidebar />
      </div>
      <div style={{ display: 'flex'}}>
          <LabTabs 
          item1='Lista de Propiedades' 
          item2='Gestion de Valores'
          item3='Añadir Propiedad'/>
      </div>
    </div>
  )
}

export default GestorPropiedades
import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, Image,StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { getInquilinoById } from '../api/clientes.api';
import { getPropiedadById } from '../api/propiedades.api';
import { format, differenceInDays, parseISO } from 'date-fns';
import { getRelationsByAlquilerId } from '../api/relAcompanantesAlquiler.api';
import config from '../config';
// Define el estilo de fuente negrita
Font.register({ family: 'Helvetica-Bold', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Helvetica-Bold.ttf' });

const MyDocument = ({ data }) => {
  const [inquilinoData, setInquilinoData] = useState(null);
  const [propiedadData, setPropiedadData] = useState(null);
  const [cantPersonas, setCantPersonas] = useState(0);
  const fechaInicio = parseISO(data.fecha_inicio);
  const fechaFin = parseISO(data.fecha_fin);
  const fechaContrato = parseISO(data.fecha_contrato);
  const diasContrato = differenceInDays(fechaFin, fechaInicio);
  const [valorCalculado, setValorCalculado] = useState(0);

  useEffect(() => {
    // Función asincrónica para cargar los datos del inquilino por su ID
    let valor;
    const loadInquilinoData = async () => {
      try {
        const response = await getInquilinoById(data.inquilino); // Suponiendo que data.inquilino contiene el ID del inquilino
        setInquilinoData(response); // Almacena los datos del inquilino en el estado
        const relAcompanantesAlquiler = await getRelationsByAlquilerId(data.id);
        // Verifica si hay datos en la propiedad 'data' antes de intentar aplicar 'filter'
        if (relAcompanantesAlquiler && relAcompanantesAlquiler.data && Array.isArray(relAcompanantesAlquiler.data)) {
          const relacionesfiltradas = relAcompanantesAlquiler.data.filter(relacion => relacion.alquiler === data.id);
          const cantidad = parseInt(relacionesfiltradas.length) + 1;
          setCantPersonas(cantidad);
        } else {
          console.error('La respuesta no contiene datos válidos.');
        }

        
      } catch (error) {
        console.error('Error al cargar los datos del inquilino:', error);
      }
    };

    // Función asincrónica para cargar los datos de la propiedad por su ID
    const loadPropiedadData = async () => {
      try {
        const response = await getPropiedadById(data.propiedad); // Suponiendo que data.propiedad contiene el ID de la propiedad
        setPropiedadData(response); // Almacena los datos de la propiedad en el estado
      } catch (error) {
        console.error('Error al cargar los datos de la propiedad:', error);
      }
    };
    // Llama a las funciones de carga de datos
    loadInquilinoData();
    loadPropiedadData();
    valor = parseFloat(data.valor) - parseFloat(data.senia) + parseFloat(data.tasa_limpieza); 
    setValorCalculado(valor);
  }, [data]);

  return (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title1}>CONTRATO DE LOCAÇÃO RESIDENCIAL PARA TEMPORADA</Text>
        <Text style={styles.subtitle}>LOCADOR:</Text>
        <Text style={styles.bold}>- Nome: JULIAN ESTEBAN LINIERS</Text>
        <Text style={styles.bold}>- DNI: 28.190.946</Text>
        <Text style={styles.bold}>- CPF: 718.828.301-05</Text>
        <Text style={styles.bold}>- Endereço: NICETO VEGA 1039 – BUENOS AIRES - ARGENTINA</Text>
        <Text style={styles.bold}>- Telefones: +5491144962617</Text>
        <Text style={styles.bold}>- E-mail: julianliniers@gmail.com</Text>
        <Text style={styles.subtitle}>INQUILINO / LOCATÁRIO:</Text>
        <Text style={styles.bold}>- Nome: {inquilinoData && inquilinoData.nombre} {inquilinoData && inquilinoData.apellido}</Text>	
        <Text style={styles.bold}>- RG/DNI: {inquilinoData && inquilinoData.documento}</Text>	
        <Text style={styles.bold}>- CPF: {inquilinoData && inquilinoData.cpf}</Text>	
        <Text style={styles.bold}>- Endereço: {inquilinoData && inquilinoData.direccion}</Text>	
        <Text style={styles.bold}>- Telefones: {inquilinoData && inquilinoData.numero_telefonico_1 }
                                               {inquilinoData && inquilinoData.numero_telefonico_2 && ` | ${inquilinoData.numero_telefonico_2}`}
                                               {inquilinoData && inquilinoData.numero_telefonico_3 && ` | ${inquilinoData.numero_telefonico_3}`}
                                               {inquilinoData && inquilinoData.numero_telefonico_4 && ` | ${inquilinoData.numero_telefonico_4}`}
        </Text>	
        <Text style={styles.bold}>- E-mail: {inquilinoData && inquilinoData.correo}</Text> 


          <Text style={styles.title}>DO OBJETO DO CONTRATO</Text>
          <Text style={styles.bold}>Cláusula 1°:</Text><Text>O objeto do presente instrumento é o imóvel residencial {propiedadData && propiedadData.nombre}, situado no {propiedadData && propiedadData.direccion}, {propiedadData && propiedadData.provincia}, {propiedadData && propiedadData.localidad} de propriedade do LOCADOR.</Text>
          <Text style={styles.title}>DO USO DO IMÓVEL</Text>
          <Text style={styles.bold}>Cláusula 2°: </Text><Text>O LOCATÁRIO deve usar o imóvel objeto deste contrato, somente com a finalidade residencial própria e de sua família, sendo alugado para uso no total de {cantPersonas} adultos, não pode aceitar visitas ou fazer festas na acomodação.</Text>

          <Text style={styles.title}>DAS OBRIGAÇÕES DO LOCATÁRIO</Text>
          <Text style={styles.bold}>Cláusula 3°: </Text><Text>Fica obrigado o LOCATÁRIO, em agir de acordo com o estabelecido  nas normas do CONDOMINIO, responsabilizando-se civil e criminalmente durante a vigência deste instrumento.</Text>
  
          <Text style={styles.bold}>Cláusula 4°:</Text><Text>Obriga-se o LOCATÁRIO em cuidar pela conservação do imóvel, bem como todos os bens que nele contém, sendo responsável em entrega-lo ao termino do prazo estipulado neste contrato, nas condições que recebeu conforme consta no termo de vistoria em anexo.</Text>

          <Text style={styles.bold}>Cláusula 5°:</Text> <Text>Fica proibido por parte do LOCATÁRIO qualquer tipo de perturbação, por exemplo, som alto e barulho no interior do imóvel.</Text>

          <Text style={styles.title}>DO PRAZO DA LOCAÇÃO</Text>
          <Text style={styles.bold}>Cláusula 6°:</Text><Text>Os contratantes ajustam pelo presente instrumento particular a locação do imóvel supra descrito, para locação por temporada, pelo período de {format(fechaInicio, 'dd/MM/yyyy')} as {data.check_in} hs, até {format(fechaFin, 'dd/MM/yyyy')} as {data.check_out} hs.</Text>

      </View>
    </Page>


    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
          <Text style={styles.title7}>DO VALOR DA LOCAÇÃO:</Text>
          <Text style={styles.bold}>Cláusula 7°:</Text><Text>O valor total do aluguel para o período acima fica ajustado em R$ {data.valor} ({data.valor} reais), referente a {diasContrato} diárias de R$ {data.valorPromedio} cada uma. Sendo pago no dia {format(fechaContrato,'dd/MM/yyyy')} o valor de R${data.senia}. Ficando um saldo a ser pago no check in de R${valorCalculado} incluindo a taxa de limpeza. </Text>

          <Text style={styles.bold}>Cláusula 8°:</Text> <Text>Será cobrada uma taxa única de limpeza de R$ {data.tasa_limpieza} ({data.tasa_limpieza} reais), devida no momento da entrada do imóvel, paga juntamente com o saldo devedor da locação.</Text>
 
          <Text style={styles.bold}>Cláusula 9°:</Text> <Text>No caso de encerramento da estadia em momento anterior ao previsto, por qualquer motivo, o locador não devolverá valores ao inquilino, no caso de desistência da locação, o locatário perderá a totalidade do sinal depositado.</Text>
 
          <Text style={styles.title}>DA DEMORA NA DESOCUPAÇÃO:</Text>
          <Text style={styles.bold}>Cláusula 10°: </Text><Text>A permanência no imóvel após o prazo contratado implicará no pagamento da diária em dobro, por dia que exceder até a definitiva desocupação, bem como perdas e danos em razão de eventual atraso na entrega do imóvel para o próximo inquilino.</Text>
   
          <Text style={styles.title}>DOS ANIMAIS DE ESTIMAÇÃO:</Text>
          <Text style={styles.bold}>Cláusula 11°: </Text><Text>Não será permitida em nenhuma hipótese a presença de animais de estimação no imóvel, de qualquer porte, ainda que transitoriamente.</Text>

          <Text style={styles.title}>DESPESAS DE ÁGUA, LUZ, GÁS, IMPOSTOS, CONDOMÍNIO, TV à CABO, INTERNET:</Text>
          <Text style={styles.bold}>Cláusula 12°: </Text><Text>O valor da locação previsto anteriormente inclui todas essas despesas, bem como outras não discriminadas expressamente neste contrato.</Text>
  
          <Text style={styles.title}>DAS OBRIGAÇÕES FINAIS DO INQUILINO:</Text>
          <Text style={styles.bold}>Cláusula 13°: </Text><Text>O inquilino se obriga a:
          a) não ceder ou franquear o imóvel para outrem, sem o prévio e expresso consentimento da administradora, mesmo que temporariamente;
          b) restituir o imóvel nas mesmas e perfeitas condições que lhe foi entregue: sem estragos avariais ou danos, inclusive aos móveis e utensílios, guarnições e demais pertences;
          c) comunicar ao locador ou administradora quaisquer ocorrências imprevistas havidas no imóvel e seus utensílios.</Text>

          <Text style={styles.title}>DA INTEGRAÇÃO DO CONTRATO</Text>
          <Text style={styles.bold}>Cláusula 14°: </Text><Text>Faz parte integrante deste contrato todos os e-mails ou mensagens trocadas pelas partes antes da assinatura do mesmo (Ex: Whatsapp), as quais podem ser utilizadas como prova de cláusulas não contidas neste contato, desde que haja prova de concordância da outra parte (ex: resposta com ciência).</Text>
      </View>
    </Page>


    <Page size="A4" style={styles.page}>
      <View style={styles.section}>

          <Text style={styles.title}>DO FORO</Text>
          <Text style={styles.bold}>Cláusula 15°: </Text><Text>Elege-se o Foro da Comarca de Cidade/Estado, para dirimir quaisquer dúvidas oriundas do presente Contrato.</Text>
        
        <Text style={styles.ultbold}>Este contrato é válido a partir do momento em que o inquilino o recebe por qualquer meio eletrônico ou físico (E-mail, WhatsApp, etc.)</Text>
        <Text style={styles.firma1}>Firma do locatario:</Text>
        <Image style={styles.firmaimg} src={`${config.baseUrl}media/media/firma.jpeg`} />
        <Text style={styles.firma}>Firma do locador:</Text>
        <Text style={styles.firma}>Testemunha 1:</Text>
        <Text style={styles.firma}>Testemunha 2:</Text>
        </View>
    </Page>
  </Document>
);
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    marginTop: 20, // Margen superior de 2,54 cm

  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    fontSize: 11,
    lineHeight: 1.5,
    fontFamily: 'Helvetica', // Fuente Arial
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  title1: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  title3: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 38,
  },
  title7: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  bold: {
    fontFamily: 'Helvetica-Bold', // Usa la fuente negrita
  },
  ultbold: {
    fontFamily: 'Helvetica-Bold', // Usa la fuente negrita
    marginTop: 20,
  },
  subtitle: {
    fontWeight: 'bold',
    marginTop: 10,
    fontFamily: 'Helvetica-Bold',
  },
  firma: {
    marginTop:'5px',
    fontFamily: 'Helvetica-Bold',
    marginBottom:'40px',
  },
  firma1: {
    marginTop:'50px',
    fontFamily: 'Helvetica-Bold',
  },
  firmaimg: {
    width: '120px',
    height: '100px',
    marginLeft:'60px'
  },

  leyenda: {
    position: 'absolute',
    bottom: 40, // Ajusta este valor según tu diseño
    left: 40, // Ajusta este valor según tu diseño
  },
});

const PDFGenerator = ({ data }) => (
  <PDFViewer width="100%" height="850px" margin="0px" padding="0px">
    <MyDocument data={data} />
  </PDFViewer>
);

export default PDFGenerator;



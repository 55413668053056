import React, { useState, useEffect } from 'react';
import '../styles/ClientsList.css';
import AccountIcon from '@mui/icons-material/AccountCircle';
import { getAllClients, deleteClients, updateClients, updateClientPhoneNumber  } from '../api/clientes.api';
import { getAllAlquileres } from '../api/alquileres.api';
import { getAcompananteByClientId, deleteAcompanante, updateAcompanante } from '../api/acompanantes.api';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateAcompananteForm from './CreateAcompananteForm';
import jwt_decode from 'jwt-decode';
import config from '../config';

export const ClientsList = () => {
  const [clients, setClients] = useState([]);
  const [editingClientId, setEditingClientId] = useState(null);
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [pasajeroId, setPasajeroId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [selectedClientInfo, setSelectedClientInfo] = useState(null);
  const [editingAcompananteId, setEditingAcompananteId] = useState(null);
  const [acompananteToEdit, setAcompananteToEdit] = useState(null);
  const [isAddAcompananteFormOpen, setIsAddAcompananteFormOpen] = useState(false);
  const [acompananteData, setAcompananteData] = useState({ inquilino: '',nombre: '', apellido: '', dni: '', cpf: '', edad: '' });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false); // Nuevo estado para el diálogo de edición
  const [telefonoInputs, setTelefonoInputs] = useState([1]);
  const [isSuperuser, setIsSuperuser] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token, config.secretKey);

        setIsSuperuser(decodedToken.is_superuser);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  }, []);

  const loadAcompanantes = async (clientId) => {
    try {
      await getAcompananteByClientId(clientId);
    } catch (error) {
      console.error('Error al obtener acompañantes:', error);
    }
  };

  const handleVerAcompanantesClick = async (clientId) => {
    try {
      const response = await getAcompananteByClientId(clientId);
      const acompanantes = response;
      setSelectedClientInfo({ clientId, acompanantes });
    } catch (error) {
      console.error('Error al obtener acompañantes:', error);
    }
  };

  const filterClients = (client) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      client.nombre.toLowerCase().includes(lowerSearchTerm) ||
      client.apellido.toLowerCase().includes(lowerSearchTerm) ||
      client.direccion.toLowerCase().includes(lowerSearchTerm) ||
      client.documento.toLowerCase().includes(lowerSearchTerm) ||
      client.correo.toLowerCase().includes(lowerSearchTerm) ||
      client.numero_telefonico_1.includes(searchTerm) ||
      client.cpf.toLowerCase().includes(lowerSearchTerm)
    );
  };

  const filteredClients = clients.filter(filterClients);

  const checkClientAlquileres = async (clientId) => {
    try {
      const response = await getAllAlquileres();
      const alquileres = response.data;
      const hasAlquileres = alquileres.some(alquiler => alquiler.inquilino === clientId);
      return hasAlquileres;
    } catch (error) {
      console.error('Error al obtener alquileres:', error);
      return false;
    }
  };

  useEffect(() => {
    async function loadClients() {
      const res = await getAllClients();
      setClients(res.data);
    }
    loadClients();
  }, []);

  const handleDeleteClient = async (clientId) => {
    const hasAlquileres = await checkClientAlquileres(clientId);
    if (hasAlquileres) {
      toast.error('No se puede eliminar este pasajero porque tiene alquileres registrados.');
    } else {
      setClientToDelete(clientId);
      setIsDeleteDialogOpen(true);
    }
  };

  const deleteClient = async (clientId) => {
    try {
      await deleteClients(clientId);
      setClients(clients.filter((item) => item.id !== clientId));
      toast.success('Pasajero eliminado exitosamente.');
    } catch (error) {
      toast.error('Ocurrió un error al eliminar el pasajero');
    }
  };

  const handleToggleMenu = (clientId) => {
    if (selectedClientInfo?.clientId === clientId) {
      // Si el menú de acompañantes ya estaba abierto, ciérralo
      setSelectedClientInfo(null);
    } else {
      // Si no, obtén y muestra los acompañantes y cierra el menú de edición si estaba abierto
      handleVerAcompanantesClick(clientId);
      setEditingClientId(null);
    }
  };

  const handleEditClick = (clientId) => {
    if (editingClientId === clientId) {
      // Si el menú de edición ya estaba abierto, ciérralo
      setEditingClientId(null);
    } else {
      // Si no, abre el diálogo de edición para el cliente seleccionado
      setEditingClientId(clientId);
      reset();
      setIsEditDialogOpen(true); // Abre el diálogo de edición
    }
  };

  const handleDeleteClientAcompanante = async (acompananteId) => {
    try {
      await deleteAcompanante(acompananteId);
      toast.success('Acompañante eliminado correctamente');
      // Establecer selectedClientInfo en null para cerrar la lista de acompañantes
      setSelectedClientInfo(null);
      navigate("/pasajeros");
    } catch (error) {
      toast.error('Error al eliminar acompañante');
      setSelectedClientInfo(null);
      console.error(error);
    }
  };
  
  const handleEditClickAcompanante = (acompanante, inquilino) => {
    if (editingAcompananteId === acompanante.id) {
      // Si el menú de edición ya estaba abierto para este acompañante, ciérralo
      setEditingAcompananteId(null);
    } else {
      setEditingAcompananteId(acompanante.id);
      setAcompananteData(acompanante);
      setAcompananteToEdit(acompanante);
      setPasajeroId(inquilino);
    }
  };

  const onSubmitAcompanante = async (event) => {
    event.preventDefault();
    if (acompananteData.nombre && acompananteData.apellido && acompananteData.dni && acompananteData.cpf && acompananteData.edad) {
      try {

        acompananteData.inquilino = pasajeroId;
        if (acompananteToEdit) {
          // Si hay un ID de acompañante válido, realiza una solicitud de actualización
          await updateAcompanante(acompananteToEdit.id, acompananteData);
          toast.success('Acompañante modificado correctamente');
          setEditingAcompananteId(null);
          setAcompananteToEdit(null);
          // Establecer selectedClientInfo en null para cerrar la lista de acompañantes
          setSelectedClientInfo(null);
          navigate('/pasajeros');
        }
      } catch (error) {
        toast.error('Error al modificar acompañante');
        console.error(error);
      }
    } else {
      setErrorMessage("Por favor, complete todos los campos del acompañante.");
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append('nombre', data.nombre);
    formData.append('apellido', data.apellido);
    formData.append('correo', data.correo);
    formData.append('numero_telefonico_1', data.numero_telefonico_1);
    formData.append('documento', data.documento);
    formData.append('direccion', data.direccion);
    formData.append('cpf', data.cpf);

    // Verificar y agregar números de teléfono adicionales
    telefonoInputs.forEach((index) => {
      const numeroTelefonoAdicional = data[`numero_telefonico_${index}`];
      if (numeroTelefonoAdicional) {
        formData.append(`numero_telefonico_${index}`, numeroTelefonoAdicional);
      }
    });

    try {
      const response = await updateClients(editingClientId, formData);
      if (response.status === 200) {
        const updatedClient = { ...data, id: editingClientId };
        setClients(clients.map(client =>
          client.id === editingClientId ? updatedClient : client
        ));
        setEditingClientId(null);
        navigate("/pasajeros");
        toast.success('Pasajero modificado correctamente');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const responseData = error.response.data;
        if (responseData.documento && responseData.documento[0] === "Este número de documento ya se encuentra registrado.") {
          setErrorMessage("Número de documento ya registrado: " + responseData.documento[0]);
        } else {
          setErrorMessage("Error al modificar pasajero.");
          console.error("Error al modificar pasajero:", error);
        }
      } else {
        setErrorMessage("Error al modificar pasajero.");
        console.error("Error al modificar pasajero:", error);
      }
    }
  });
  const addTelefonoInput = () => {
    if (telefonoInputs.length < 4) {
      setTelefonoInputs([...telefonoInputs, telefonoInputs.length + 1]);
    }
  };


  useEffect(() => {
    if (editingClientId !== null) {
      const clientToEdit = clients.find(client => client.id === editingClientId);
      if (clientToEdit) {
        setValue("nombre", clientToEdit.nombre);
        setValue("apellido", clientToEdit.apellido);
        setValue("direccion", clientToEdit.direccion);
        setValue("correo", clientToEdit.correo);
        setValue("numero_telefonico_1", clientToEdit.numero_telefonico_1);
        setValue("documento", clientToEdit.documento);
        setValue("cpf", clientToEdit.cpf);
  
        // Verificar si hay más de un número de teléfono
        const telefonoKeys = Object.keys(clientToEdit)
          .filter(key => key.startsWith('numero_telefonico_'))
          .sort();
  
        telefonoKeys.forEach((key, index) => {
          if (index > 0) {
            // A partir del segundo número de teléfono, asignar valores y mostrarlo
            setValue(key, clientToEdit[key]);
            // Aquí puedes realizar cualquier acción adicional para mostrar el teléfono adicional
          }
        });
      }
    }
  }, [editingClientId, clients, setValue]);

  const removeTelefonoInput = async (index, cliente) => {

    try {
      await updateClientPhoneNumber(cliente.id,' ', index);
      navigate('/pasajeros');
      toast.success("Telefono adicional eliminado correctamente");
      const updatedClients = await getAllClients();
      setClients(updatedClients.data);
    } catch (error) {
      console.error(`Error al actualizar el número de teléfono`, error);
      toast.error("Hubo un problema al eliminar telefono adicional");
    }
  };

  return (
    <div className="clients-list">
      <div className="input_busqueda">
        <input
          type="text"
          placeholder="Buscar pasajero"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {filteredClients.length === 0 ? (
        <p>No hay pasajeros registrados</p>
      ) : (
        <div className="client-container">
          {filteredClients.map((client) => (
            <div key={client.id} className="cliente-item">
              <div className="client-header">
                <AccountIcon className="icono" />
                <h3>{client.nombre} {client.apellido}</h3>
              </div>
              <div className="client-info">
                <div className="client-details">
                <div>
                    <strong>Direccion:</strong> {client.direccion}
                  </div>
                  <div>
                    <strong>Documento / RG:</strong> {client.documento}
                  </div>
                  <div>
                    <strong>CPF:</strong> {client.cpf}
                  </div>
                  <div>
                    <strong>Correo:</strong> {client.correo}
                  </div>
                  <div>
                    <strong>Teléfono:</strong> {client.numero_telefonico_1}
                  </div>
                  {client.numero_telefonico_2 && (
                  <div>
                    <strong>Teléfono Adicional 2:</strong> {client.numero_telefonico_2} 
                    <Button  onClick={() => removeTelefonoInput(2,client)}>
                          <DeleteIcon />
                    </Button>
                  </div>
                )}
                {client.numero_telefonico_3 && (
                  <div>
                    <strong>Teléfono Adicional 3:</strong> {client.numero_telefonico_3}
                    <Button  onClick={() => removeTelefonoInput(3,client)}>
                          <DeleteIcon />
                    </Button>
                  </div>
                )}
                {client.numero_telefonico_4 && (
                  <div>
                    <strong>Teléfono Adicional 4:</strong> {client.numero_telefonico_4}
                    <Button   onClick={() => removeTelefonoInput(4,client)}>
                          <DeleteIcon />
                    </Button>
                  </div>
                )}
                </div>
                <div className="client-buttons">
                  <Button  onClick={() => handleEditClick(client.id)}>
                    <EditIcon />
                  </Button>
                  {isSuperuser && (
                  <Button  onClick={() => handleDeleteClient(client.id)}>
                    <DeleteIcon />
                  </Button>
                  )}
                  <Button  onClick={() => { handleToggleMenu(client.id); setIsAddAcompananteFormOpen(false); setEditingAcompananteId(null); }}>
                    <b>{selectedClientInfo?.clientId === client.id ? '-' : '+'}</b>
                  </Button>
                </div>
              </div>

              {selectedClientInfo?.clientId === client.id && (
                <div className="acompanantes-list-container">
                  <div className="acompanantes-list">
                    <h4>Acompañantes de {client.nombre} {client.apellido}</h4>
                    <ul>
                      {selectedClientInfo.acompanantes.map((acompanante) => {
                        if (acompanante.inquilino === client.id) {
                          return (
                            <li key={acompanante.id}>
                              Nombre: {acompanante.nombre} {acompanante.apellido}  |  Documento / RG: {acompanante.dni} | CPF: {acompanante.cpf} |  Edad: {acompanante.edad}
                              <Button  onClick={() => handleEditClickAcompanante(acompanante,acompanante.inquilino)}>
                                <EditIcon />
                              </Button>
                              {isSuperuser && (
                              <Button
                              onClick={() => {
                                if (acompanante.activo) {
                                  // Si el acompañante está activo, muestra un mensaje o realiza alguna acción
                                  toast.error('No se puede eliminar un acompañante activo.');
                                } else {
                                  // Si el acompañante no está activo, permite la eliminación
                                  handleDeleteClientAcompanante(acompanante.id);
                                }

                              }}
                            >
                              <DeleteIcon />
                            </Button>
                            )}
                            </li>
                          );
                        }
                        return null;
                      })}
                      {selectedClientInfo.acompanantes.every((acompanante) => acompanante.inquilino !== client.id) && (
                        <li>NO TIENE ACOMPAÑANTES INGRESADOS</li>
                      )}
                      
                      {editingAcompananteId === acompananteToEdit?.id && (
                        <div className="acompanantes-form">
                          {/* Campos para el acompañante */}
                          <input
                            type="text"
                            placeholder="Nombre del Acompañante"
                            {...register("nombre", { required: true })}
                          />
                          {errors.nombre && <span className="error-message">Este campo es requerido</span>}

                          <input
                            type="text"
                            placeholder="Apellido del Acompañante"
                            {...register("apellido", { required: true })}
                          />
                          {errors.apellido && <span className="error-message">Este campo es requerido</span>}

                          <input
                            type="text"
                            placeholder="DNI del Acompañante"
                            {...register("dni", { required: true })}
                          />
                          {errors.dni && <span className="error-message">Este campo es requerido</span>}

                          <input
                            type="text"
                            placeholder="CPF del Acompañante"
                            {...register("cpf", { required: true })}
                          />
                          {errors.cpf && <span className="error-message">Este campo es requerido</span>}

                          <input
                            type="text"
                            placeholder="Edad del Acompañante"
                            {...register("edad", { required: true })}
                          />
                          {errors.edad && <span className="error-message">Este campo es requerido</span>}

                          {/* Agregar otros campos para el acompañante aquí */}
                          <button onClick={handleSubmit(onSubmitAcompanante)}>Modificar Acompañante</button>
                        </div>
                      )}
                      <Button onClick={() => setIsAddAcompananteFormOpen((prevState) => !prevState)}>
                        {isAddAcompananteFormOpen ? 'CERRAR FORMULARIO' : 'AÑADIR ACOMPAÑANTE'}
                      </Button>

                      {isAddAcompananteFormOpen ? (
                        <CreateAcompananteForm
                          inquilinoId={selectedClientInfo?.clientId}
                          onAcompananteCreated={() => {
                            setIsAddAcompananteFormOpen(false);
                            // Establecer selectedClientInfo en null para cerrar la lista de acompañantes
                            setSelectedClientInfo(null);
                            // Actualiza la lista de acompañantes llamando a loadAcompanantes o cualquier otro método que uses para cargar acompañantes
                            loadAcompanantes(selectedClientInfo?.clientId);
                          }}
                        />
                      ) : null}
                    </ul>
                  </div>
                </div>
              )}

              {/* Diálogo de edición del cliente */}
              <Dialog
                open={isEditDialogOpen && editingClientId === client.id}
                onClose={() => setIsEditDialogOpen(false)}
                aria-labelledby="edit-client-dialog-title"
              >
                <DialogTitle id="edit-client-dialog-title">Editar Pasajero</DialogTitle>
                <DialogContent  className=" formulario form" style={{height:'430px', width:'400px'}}>
                  <form onSubmit={onSubmit}  className="add-properties-form">
                    <input type="text" placeholder='Nombre' {...register("nombre", { required: true })} />
                    {errors.nombre && <span className="error-message">Este campo es requerido</span>}
                    <input type="text" placeholder='Apellido' {...register("apellido", { required: true })} />
                    {errors.apellido && <span className="error-message">Este campo es requerido</span>}
                    <input type="text" placeholder='Direccion' {...register("direccion", { required: true })} />
                    {errors.direccion && <span className="error-message">Este campo es requerido</span>}
                    <input type="text" placeholder='Documento / RG' {...register("documento")} />
                    {errors.documento && <span className="error-message">Este campo es requerido</span>}
                    <input type="text" placeholder='CPF' {...register("cpf")} />
                    {errors.documento && <span className="error-message">Este campo es requerido</span>}
                    {/* Campos para el número de teléfono */}
                    {telefonoInputs.map((index) => (
                      <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <input type="text" placeholder={`Número de Teléfono ${index}: *`} {...register(`numero_telefonico_${index}`, { required: true })} />
                        {errors[`numero_telefonico_${index}`] && <span className="error-message">Este campo es requerido</span>}
                      </div>
                    ))}
                    <button type="button" onClick={addTelefonoInput} style={{ padding: '3px' }}>Telefonos adicionales</button>
                    <input type="text" placeholder='Correo' {...register("correo", { required: true })} />
                    {errors.correo && <span className="error-message">Este campo es requerido</span>}
                    <button className="submit-button" >Modificar Pasajero</button>
                    {errorMessage && <div className="error-message">El documento ya se encuentra ingresado</div>}
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button style={{marginRight:'180px'}}onClick={() => setIsEditDialogOpen(false)} color="primary">
                    Cancelar
                  </Button>
                </DialogActions>
              </Dialog>
              {/* Fin del diálogo de edición del cliente */}
            </div>
          ))}
        </div>
      )}

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que deseas eliminar este cliente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setIsDeleteDialogOpen(false);
              deleteClient(clientToDelete);
            }}
            color="primary"
            autoFocus
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};




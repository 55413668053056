import React, { useEffect, useState } from 'react';
import { getAllGastos , deleteGasto, updateGasto } from '../api/gastos.api';
import { getPropiedadById } from '../api/propiedades.api';
import EditIcon from '@mui/icons-material/Edit';
import { getAllProperties } from '../api/propiedades.api';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import '../styles/GastosList.css';
import { generatePDFReport } from './GastosReport';

export default function EnlistarGastos() {
  const [gastos, setGastos] = useState([]);
  const [propiedadesData, setPropiedadesData] = useState({});
  const [propiedades, setPropiedades] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedGastoId, setSelectedGastoId] = useState(null);
  const [orden, setOrden] = useState('reciente');
  const [editDialogOpen, setEditDialogOpen] = useState(false); 
  const navigate = useNavigate();
  const [editingGasto, setEditingGasto] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedConcept, setSelectedConcept] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [formData, setFormData] = useState({
    propiedad: '',
    concepto: '',
    descripcion: '',
    monto: '',
    moneda:'',
    fecha: ''
  });
  const [conceptos, setConceptos] = useState([]);

  const cambiarOrden = (nuevoOrden) => {
    setOrden(nuevoOrden);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const openDeleteDialog = (gastoId) => {
    setSelectedGastoId(gastoId);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setSelectedGastoId(null);
    setDeleteDialogOpen(false);
  };

  const confirmDelete = async () => {
    if (selectedGastoId) {
      try {
        await deleteGasto(selectedGastoId);
        setGastos(gastos.filter((item) => item.id !== selectedGastoId));
        closeDeleteDialog();
        toast.success('Gasto eliminado exitosamente.');
      } catch (error) {
        toast.error('Ocurrio un error al eliminar el gasto');
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateGasto(editingGasto, formData);
      if (response.status === 200){
        const updatedGasto = { ...formData, id: editingGasto};
        setGastos(gastos.map(gastos =>
            gastos.id === editingGasto ? updatedGasto : gastos));
      }
      setEditingGasto(null); // Limpiar el estado de edición
      closeEditDialog();
      navigate("/gastos");
      toast.success('Gasto actualizado correctamente');
    } catch (error) {
      toast.error('Error al actualizar gasto');
    }
  };

  useEffect(() => {
    // Lógica para obtener los conceptos 
    const conceptosFromBackend = [
      'Gastos de Administración',
      'Gastos de Limpieza',
      'Gastos Legales y Administrativos',
      'Compras de Equipos / Electrodomesticos',
      'Condominio',
      'Luz ( CELESC )',
      'Honorarios / Comisiones de Agentes',
      'IPTU',
      'Internet y Cable CLARO',
      'Impuestos y Tasas',
      'Mantenimiento y Reparaciones',
      'Muebles y Equipamiento',
      'Seguro de Propiedad',
      'Servicios Públicos',
      'Varios',
    ];
    setConceptos(conceptosFromBackend);
  }, [])

  useEffect(() => {
    async function loadData() {
      const gastosRes = await getAllGastos();
      setGastos(gastosRes.data);

      // Obtener detalles de todas las propiedades
      const propiedadesIds = gastosRes.data.map((gasto) => gasto.propiedad);
      const propiedadesData = await Promise.all(
        propiedadesIds.map((id) => getPropiedadById(id))
      );

      // Crear un objeto con los detalles de las propiedades
      const propiedadesDataObj = {};
      propiedadesIds.forEach((id, index) => {
        propiedadesDataObj[id] = propiedadesData[index];
      });
      setPropiedadesData(propiedadesDataObj);
    }

    async function loadPropiedades() {
      const res = await getAllProperties();
      setPropiedades(res);
    }

    loadData();
    loadPropiedades();
  }, []);

  const handleGeneratePDF = () => {
    // Aplicar filtros solo si se ha seleccionado una propiedad, concepto o rango de fechas
    let filteredGastos = gastos;
  
    if (selectedProperty) {
      filteredGastos = filteredGastos.filter((gasto) => gasto.propiedad == selectedProperty);
    }
  
    if (selectedConcept) {
      filteredGastos = filteredGastos.filter((gasto) => gasto.concepto === selectedConcept);
    }
  
    if (startDate && endDate) {
      filteredGastos = filteredGastos.filter((gasto) => {
        const gastoDate = new Date(gasto.fecha);
        const filterStartDate = new Date(startDate);
        const filterEndDate = new Date(endDate);
  
        return gastoDate >= filterStartDate && gastoDate <= filterEndDate;
      });
    }
  
    // Generar el informe PDF con los datos filtrados o sin filtrar
    const datosInforme = obtenerDatosInforme(filteredGastos);
    generatePDFReport(datosInforme);
  };

  const obtenerDatosInforme = (gastos) => {
    // Realiza cualquier manipulación necesaria en tus datos de gastos
    const datosInforme = gastos.map((gasto) => ({
      propiedad: propiedadesData[gasto.propiedad]?.direccion,
      concepto: gasto.concepto,
      descripcion: gasto.descripcion,
      monto: gasto.monto,
      fecha: gasto.fecha,
    }));
    return datosInforme;
  };

  // Nueva función para abrir el diálogo de edición
  const openEditDialog = (gastoId) => {
    setEditingGasto(gastoId);
    const gastoToEdit = gastos.find(item => item.id === gastoId);
    setFormData({
      propiedad: gastoToEdit.propiedad,
      concepto: gastoToEdit.concepto,
      descripcion: gastoToEdit.descripcion,
      monto: gastoToEdit.monto,
      moneda: gastoToEdit.moneda, // Asegúrate de que moneda se incluya en los datos del gasto
      fecha: gastoToEdit.fecha
    });
    setEditDialogOpen(true);
  };

  // Nueva función para cerrar el diálogo de edición
  const closeEditDialog = () => {
    setEditingGasto(null);
    setEditDialogOpen(false);
  };
  

  // Ordenar los gastos según la elección del usuario (puedes ajustar esta lógica según tus necesidades)
  const sortedGastos = orden === 'reciente'
    ? gastos.sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
    : gastos.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));

  // Aplicar filtros según el valor del estado de filtro
  const filteredGastos = sortedGastos.filter(gasto => {
    const propiedadData = propiedadesData[gasto.propiedad];
    const direccionPropiedad = propiedadData ? propiedadData.direccion : '';

    const cumpleFiltro = filtro === '' ||
      direccionPropiedad.includes(filtro) ||
      gasto.concepto.includes(filtro);

    return cumpleFiltro;
  });

  return (
    <div>
    <div className="gastos-list">
      <div className="filters-and-buttons">
        <input
          className="filter-input"
          type="text"
          placeholder="Filtrar por dirección de propiedad o concepto"
          value={filtro}
          onChange={e => setFiltro(e.target.value)}
        />
        <div className="buttons">
          <button className="submit-buttongasto" onClick={() => cambiarOrden('reciente')}>Ordenar Reciente</button>
          <button className="submit-buttongasto" onClick={() => cambiarOrden('antigua')}>Ordenar Antigua</button>
        </div>
      </div>
      <div className="gastos-table">
      <table>
        <thead>
          <tr>
            <th>Propiedad</th>
            <th>Concepto</th>
            <th>Monto</th>
            <th>Moneda</th>
            <th>Fecha</th>
            <th>Descripcion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredGastos.length === 0 ? (
            <tr>
              <td colSpan="7">No hay gastos registrados</td>
            </tr>
          ) : (
            <>
              {filteredGastos.map((gasto) => (
                <tr key={gasto.id} className="gasto-item">
                  <td className="table-item" style={{ fontSize: "15px" }}>
                    {propiedadesData[gasto.propiedad]?.direccion}
                  </td>
                  <td className="table-item" style={{ fontSize: "15px" }}>
                    {gasto.concepto}
                  </td>
                  <td className="table-item" style={{ fontSize: "15px" }}>
                    {gasto.monto}
                  </td>
                  {gasto.moneda === 'pesos_argentinos' && (
                      <td className="table-item" style={{ fontSize: "15px" }}>
                      pesos argentinos
                    </td>
                   )}
                  {gasto.moneda != 'pesos_argentinos' && (
                      <td className="table-item" style={{ fontSize: "15px" }}>
                      {gasto.moneda}
                    </td>
                   )}
                  <td className="table-item" style={{ fontSize: "15px" }}>
                    {gasto.fecha}
                  </td>
                  <td className="table-item" style={{ fontSize: "15px" }}>
                    {gasto.descripcion}
                  </td>
                  <td className="table-item">
                    <div className="table-buttons">
                      <Button
                        className="button_edit-gasto boton"
                        onClick={() => openEditDialog(gasto.id)}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        className="delete-button boton"
                        onClick={() => openDeleteDialog(gasto.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que deseas eliminar este gasto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen} // Abrir el diálogo de edición cuando se active
        onClose={closeEditDialog} // Cerrar el diálogo de edición cuando se haga clic en "Cancelar"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Editar Gasto</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div className="form-group edicionGasto">
              <label htmlFor="propiedad">Propiedad:</label>
              <select
                className="input-select"
                name="propiedad"
                value={formData.propiedad}
                onChange={handleChange}
              >
                <option value="">Seleccionar Propiedad</option>
                {propiedades.map((propiedad) => (
                  <option key={propiedad.id} value={propiedad.id}>
                    {propiedad.direccion}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="concepto">Concepto:</label>
              <select
                className="input-select"
                name="concepto"
                value={formData.concepto}
                onChange={handleChange}
              >
                <option value="">Seleccionar un concepto</option>
                {conceptos.map((concepto) => (
                  <option key={concepto} value={concepto}>
                    {concepto}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="descripcion">Descripción:</label>
              <textarea
                className="input-textarea"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="monto">Monto:</label>
              <input
                className="input-monto campo"
                type="number"
                name="monto"
                value={formData.monto}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="moneda">Moneda:</label>
              <select
                className="input-select"
                name="moneda"
                value={formData.moneda}
                onChange={handleChange}
              >
                <option value="">Seleccionar una moneda</option>
                <option value="reales">Reales</option>
                <option value="pesos_argentinos">Pesos Argentinos</option>
                <option value="dolares">Dólares</option>
                <option value="euros">Euros</option>
                <option value="libras">Libras</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="fecha">Fecha:</label>
              <input
                className="input-fecha campo"
                type="date"
                name="fecha"
                value={formData.fecha}
                onChange={handleChange}
              />
            </div>
            <DialogActions>
              <Button onClick={closeEditDialog} color="primary">
                Cancelar
              </Button>
              <Button type="submit" color="primary">
                Guardar
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
         {/* Selector de propiedad */}
         <select
          value={selectedProperty}
          onChange={(e) => setSelectedProperty(e.target.value)}
          className="informe"
        >
          <option value="">Todas las propiedades</option>
          {propiedades.map((propiedad) => (
            <option key={propiedad.id} value={propiedad.id}>
              {propiedad.direccion}
            </option>
          ))}
        </select>

        {/* Selector de concepto */}
        <select
          value={selectedConcept}
          onChange={(e) => setSelectedConcept(e.target.value)}
          className="informe"
        >
          <option value="">Todos los conceptos</option>
          {conceptos.map((concepto) => (
            <option key={concepto} value={concepto}>
              {concepto}
            </option>
          ))}
        </select>

        {/* Selector de rango de fechas */}
        Desde:
        <input
          type="date"
          placeholder="Fecha inicial"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="informe"
        />
        Hasta:
        <input
          type="date"
          placeholder="Fecha final"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="informe"
        />
      <button className="submit-buttongasto buttonInforme" onClick={handleGeneratePDF}>
        Generar Informe PDF
      </button>
    </div>
  );
}


import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { getAllProperties, getPropiedadById } from '../api/propiedades.api';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { getAllClients } from '../api/clientes.api';
import { createAlquileres } from '../api/alquileres.api';
import { getAcompananteByClientId, updateAcompanante } from '../api/acompanantes.api'; // Importa las funciones para manejar los acompañantes
import '../styles/AddAlquiler.css';
import { toast } from 'react-toastify';
import PDFGenerator from './MyDocument';
import { getValoresByPropiedad } from '../api/valores.api';
import { createRelations } from '../api/relAcompanantesAlquiler.api';

export const AddAlquiler = ({ setShouldRefresh }) => {
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [clients, setClients] = useState([]);
  const { control, handleSubmit, formState: { errors }, register, watch, setValue, getValues } = useForm();

  const [alquilerCalculado, setAlquilerCalculado] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(
    properties.length > 0 ? properties[0].id : ''
  );

  const [acompanantes, setAcompanantes] = useState([]); // Estado para almacenar los acompañantes
  const [mostrarAcompanantes, setMostrarAcompanantes] = useState(true);
  const [bandera,setBandera] = useState(false);
  const fechaInicio = watch('fecha_inicio');
  const fechaFin = watch('fecha_fin');
  const seleccionCalculo = watch('calculoAlquiler');

  useEffect(() => {
    async function loadData() {
      const propertiesRes = await getAllProperties();
      setProperties(propertiesRes);

      const clientsRes = await getAllClients();
      setClients(clientsRes.data);
    }

    loadData();
  }, []);

  const [valorDiarioAlquiler, setValorDiarioAlquiler] = useState(
    selectedProperty ? selectedProperty.valorDiario : 0
  );

  const [valorPropiedadId, setValorPropiedadId] = useState(
    selectedProperty ? selectedProperty.id : 0
  );

  const calcularValorAlquilerConValorDiario = (fechaInicio, fechaFin, valorDiario) => {
    if (fechaInicio && fechaFin && valorDiario) {
      const fechaInicioObj = new Date(fechaInicio);
      const fechaFinObj = new Date(fechaFin);
      const diferenciaEnMilisegundos = fechaFinObj - fechaInicioObj;
      const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / 86400000);
      const valorCalculado = valorDiario * diferenciaEnDias;
      return valorCalculado;
    }
    return 0;
  };

  useEffect(() => {
    setBandera(false);
    if (seleccionCalculo == 2){
    const calcularValor = async () => {
      try {
        const valorCalculado = await calcularValorAlquiler(fechaInicio, fechaFin, valorPropiedadId, valorDiarioAlquiler);
        setAlquilerCalculado(valorCalculado);
      } catch (error) {
        console.error('Error al calcular el valor del alquiler en useEffect:', error);
      }
    };
  
    calcularValor();
  }
  else{
    const valorCalculado = calcularValorAlquilerConValorDiario(fechaInicio, fechaFin, valorDiarioAlquiler);
    setAlquilerCalculado(valorCalculado);
  }
  }, [fechaInicio, fechaFin, valorPropiedadId, valorDiarioAlquiler, seleccionCalculo]);

  useEffect(() => {
    if (properties.length > 0) {
      setValue('propiedad', properties[0].id);
    }
  }, [properties, setValue]);

  // Función para obtener y actualizar la lista de acompañantes al seleccionar un inquilino
  const handleInquilinoChange = async (event) => {
    const clientId = event.target.value;
    setValue('inquilino', clientId); // Establece el valor del inquilino en el formulario

    // Obtén los acompañantes del inquilino seleccionado
    try {
      const acompanantesData = await getAcompananteByClientId(clientId);
      setAcompanantes(acompanantesData);
      acompanantesData.filter(acompanante => acompanante.activo);
    } catch (error) {
      console.error('Error al obtener los acompañantes:', error);
      setAcompanantes([]); // Limpiar la lista de acompañantes
      setMostrarAcompanantes(false);
    }
  };

  const handlePropertyChange = async (event) => {
    const propertyId = event.target.value;
    setSelectedPropertyId(propertyId);
    setValorPropiedadId(propertyId);

    try {
      const property = await getPropiedadById(propertyId);
      setSelectedProperty(property);

      setValue('propiedad', propertyId);
    } catch (error) {
      console.error('Error al obtener la propiedad:', error);
      setSelectedProperty(null);
    }
  };

  useEffect(() => {
    if (selectedProperty) {
      setValorDiarioAlquiler(selectedProperty.valorDiario);
    }
  }, [selectedProperty]);
  

  const calcularValorAlquiler = async (fechaInicio, fechaFin, propiedadId, valorDiarioAlq) => {
    let ultValorDiario = 0;
    try {
      if (fechaInicio && fechaFin && propiedadId) {
        const fechaInicioObj = new Date(fechaInicio);
        const fechaFinObj = new Date(fechaFin);
  
      // Obtén todos los valores asociados a la propiedad seleccionada
      const valoresTodos = await getValoresByPropiedad(propiedadId);

      // Filtra solo los valores que corresponden a la propiedad específica
      const valores = valoresTodos.filter(valor => valor.propiedad == propiedadId);

  
        let valorTotal = 0;
  
        // Itera sobre cada día del periodo de alquiler
        for (let fechaActual = new Date(fechaInicioObj); fechaActual <= fechaFinObj; fechaActual.setDate(fechaActual.getDate() + 1)) {
          const fechaActualObj = new Date(fechaActual);
 
  
          // Busca el valor correspondiente a la fecha actual
          const valorDiario = valores.find(valor => {
            const valorDesde = new Date(valor.fecha_desde);
            const valorHasta = valor.fecha_hasta ? new Date(valor.fecha_hasta) : fechaFinObj;
          
            return fechaActualObj >= valorDesde && fechaActualObj <= valorHasta;
          });
          // Si se encuentra un valor, lo suma al total
          if (valorDiario) {
            valorTotal += parseFloat(valorDiario.valor_diario); // Usa el valorDiario del rango o el de la propiedad si no hay valorDiario específico
            ultValorDiario = parseFloat(valorDiario.valor_diario);
          }
          else{
            valorTotal +=parseFloat(valorDiarioAlq);
            ultValorDiario = parseFloat(valorDiarioAlq);
            setBandera(true);
          }
        }

        const valorCalculado = valorTotal || 0; // Usa el valorTotal calculado o el de la propiedad si no hay rangos que lo modifiquen
        const valorFinal = valorCalculado - ultValorDiario; // le resta 1 dia
        return valorFinal;
      }
      return 0;
    } catch (error) {
      console.error('Error al calcular el valor del alquiler:', error);
      return 0;
    }
  };

  const onSubmit = async (data) => {
    let cantidad = 0;
    const fechaInicioObj = new Date(data.fecha_inicio);
    const fechaFinObj = new Date(data.fecha_fin);
    try {
      // Filtra los acompañantes que pertenecen al inquilino seleccionado
      const acompanantesId = [];
      const acompanantesDelInquilino = acompanantes.filter(
        (acompanante) => acompanante.inquilino == data.inquilino
      );
      // Verifica si existen acompañantes antes de intentar actualizarlos
      if (acompanantesDelInquilino.length > 0) {

        const selectedAcompanantes = getValues("acompanantes");
        const acompanantesToUpdate = [];
        let ban = 0;
  
        acompanantesDelInquilino.forEach((acompanante) => {
          const acompananteId = acompanante.id;
          const selectedAcompanante = selectedAcompanantes[acompananteId];
  
          if (selectedAcompanante) {
            // Si el acompañante está seleccionado, agregar todos sus datos
            acompanantesToUpdate.push({
              id: acompananteId,
              activo: selectedAcompanante.activo,
              inquilino: acompanante.inquilino,
              dni: acompanante.dni,
              cpf: acompanante.cpf,
              nombre: acompanante.nombre,
              apellido: acompanante.apellido,
              edad: acompanante.edad,
            });
          } else {
            // Si el acompañante no está seleccionado, desactivarlo
            acompanantesToUpdate.push({
              id: acompananteId,
              activo: false,
              inquilino: acompanante.inquilino,
              dni: acompanante.dni,
              cpf: acompanante.cpf,
              nombre: acompanante.nombre,
              apellido: acompanante.apellido,
              edad: acompanante.edad,
            });
          }
        });
  
  
        // Actualizar los acompañantes en la base de datos
        await Promise.all(
          acompanantesToUpdate.map(async (acompanante) => {
            try {
              if (acompanante.activo){
                acompanantesId[ban] = acompanante.id;
                ban += 1;
              }  
              await updateAcompanante(acompanante.id, {
                inquilino: acompanante.inquilino,
                dni: acompanante.dni,
                cpf: acompanante.cpf,
                nombre: acompanante.nombre,
                apellido: acompanante.apellido,
                edad: acompanante.edad,
                activo: acompanante.activo,
              });
            } catch (error) {
              console.error('Error al actualizar el acompañante:', error);
            }
          })
        );
      }
      const valorCalculado = (parseInt(data.valor) + parseInt(data.tasa_limpieza)) - parseInt(data.senia);
      const valorTotalCalculado = (parseInt(data.valor) + parseInt(data.tasa_limpieza));
      data.valorCalculado = valorTotalCalculado;
      data.valorAdeudado = valorCalculado;

      for (let fechaActual = new Date(fechaInicioObj); fechaActual <= fechaFinObj; fechaActual.setDate(fechaActual.getDate() + 1)) {
        cantidad += 1;
      }
      data.cantidad_dias = (cantidad - 1); // LE RESTO UN DIA PORQUE NO CUENTA LA FECHA FINAL
      data.valorPromedio = parseFloat(data.valor / data.cantidad_dias);
      
      const hoy = new Date();
      data.fecha_contrato = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
      
      // Continúa con la creación del alquiler aquí
      const formData = new FormData(); 
      const responseAlquiler = await createAlquileres(data);
      const alquilerId = responseAlquiler.data.id;
      for (let i = 0; i < acompanantesId.length; i++) {
        try {
          const acompananteId = parseInt(acompanantesId[i]);
          await createRelations(formData, acompananteId, alquilerId);
        } catch (error) {
          console.error(`Error al crear relación para acompañante con ID ${acompanantesId[i]}`, error);
          // Puedes manejar el error de la manera que consideres adecuada
        }
      }
      setShouldRefresh(true);
      navigate('/alquileres');
      toast.success('Alquiler creado correctamente');
  
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        const pdfContent = <PDFGenerator data={responseAlquiler.data} />;
        ReactDOM.render(pdfContent, newWindow.document.body);
      } else {
        toast.error('No se pudo abrir una nueva pestaña para el contrato');
      }
    } catch (error) {
      toast.error('Error al crear el alquiler:', error);
    }
  };

  const toggleAcompanantes = () => {
    setMostrarAcompanantes(!mostrarAcompanantes);
  };

  return (
    <div className="containerAlqAdd">
      <div className="form-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="propiedad-inquilino-container">

        <div className="form-group colpropinq">
            <label htmlFor="inquilino">Inquilino: *</label>
            <select
              {...register("inquilino", { required: true })}
              onChange={handleInquilinoChange}
            >
              <option value="">Seleccionar Inquilino</option>
              {clients.map(client => (
                <option key={client.id} value={client.id}>
                  {client.nombre} {client.apellido}
                </option>
              ))}
            </select>
            {errors.inquilino && <p className="error-message">Este campo es requerido</p>}
          </div>

          <div className="form-group colpropinq">
            <label htmlFor="propiedad">Propiedad: *</label>
            <Controller
              name="propiedad"
              control={control}
              defaultValue={properties.length > 0 ? properties[0].id : ''}
              render={({ field }) => (
                <select
                  {...register('propiedad', { required: true })}
                  onChange={handlePropertyChange}
                  value={selectedPropertyId}
                >
                  <option value="">Seleccionar Propiedad</option>
                  {properties.map(property => (
                    <option key={property.id} value={property.id}>
                      {property.direccion} - {property.nombre} ({property.valorDiario})
                    </option>
                  ))}
                </select>
              )}
            />
            {errors.propiedad && <p className="error-message">Este campo es requerido</p>}
          </div>
        </div>
          <button
            type="button"
            className="toggle-acompanantes-button"
            onClick={toggleAcompanantes}
          >
            {mostrarAcompanantes ? 'Ocultar acompañantes' : 'Ver acompañantes'}
          </button>
          {/* Lista de acompañantes */}
          {mostrarAcompanantes && (
            <div className="acompanantes-list form-group">
              {acompanantes.length > 0 ? (
                acompanantes.map(acompanante => {
                  if (acompanante.inquilino == watch("inquilino")) {
                    return (
                      <div key={acompanante.id} className="acompanante-item">
                        <input
                          type="checkbox"
                          {...register(`acompanantes.${acompanante.id}.activo`)}
                          defaultChecked={acompanante.activo}
                        />
                        <p>{acompanante.nombre} {acompanante.apellido}</p>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <p>No posee acompañantes ingresados.</p>
              )}
            </div>
          )}

          <div className="form-groupfec">
              <div className="columnFec">
                <label htmlFor="fecha_inicio">Fecha de Inicio: *</label><br></br>
                <input type="date" {...register("fecha_inicio", { required: true })} />
                {errors.fecha_inicio && <p className="error-message">Este campo es requerido</p>}
              </div>
              <div className="columnFec">
                <label htmlFor="check_in">Check-in</label><br/>
                <input type="time" {...register("check_in")} defaultValue="15:00" />
              </div>
            </div>
          <div className="form-groupfec">
              <div className="columnFec">
                <label htmlFor="fecha_fin">Fecha de Fin: *</label><br></br>
                <input type="date" {...register("fecha_fin", { required: true })} />
                {errors.fecha_fin && <p className="error-message">Este campo es requerido</p>}
              </div>
              <div className="columnFec">
                <label htmlFor="check_out">Check-out</label><br/>
                <input type="time" {...register("check_out")} defaultValue="10:00" />
              </div>
            </div>

            <div className="form-group-horizontal">
            <div className="form-group columnas">
            <label htmlFor="valorDiario">Valor Diario (por defecto)</label> 
            <input
              type="number"
              step="0.01"
              {...register("valorDiario", { required: true, min: 0 })}
              value={valorDiarioAlquiler !== 0 ? valorDiarioAlquiler : ''}
              placeholder={valorDiarioAlquiler !== 0 ? '' : 'Monto del valor diario'}
              onChange={(e) => setValorDiarioAlquiler(e.target.value)}
            />
          </div>
          
           <div className="form-group columnas">
            <label htmlFor="tasa_limpieza">Tasa de Limpieza: *</label>
            <input
              type="number"
              step="0.01"
              {...register("tasa_limpieza", {  required: true,min: 0 })}
              placeholder="Tasa de Limpieza"
            />
             {errors.tasa_limpieza && <p className="error-message">Este campo es requerido</p>}
          </div>
          </div>

          <div>
            <label>Tipo de valor para el cálculo: </label>
            <Controller
              name="calculoAlquiler"
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value="1">Valor diario (por defecto)</option>
                  <option value="2">Valor por rangos</option>
                </select>
              )}
            />
          </div>
          <div className="form-group-horizontal">
            <div className="form-group columnas">
              <label htmlFor="valor">Valor de Alquiler: *</label>
              <input
                type="number"
                step="0.01"
                {...register("valor", { required: true, min: 0 })}
                placeholder={`Valor calculado: $${alquilerCalculado}` || ''}
              />
              {errors.valor && <p className="error-message">Este campo es requerido</p>}
            </div>
            {bandera && (
              <div>
              <p style={{fontSize:'10px',color:'red', marginBottom:'5px'}}>Se ha utilizado el valor diario por defecto de la propiedad</p>
              </div>
            )}

            <div className="form-group columnas senia">
              <label htmlFor="senia">Seña: *</label>
              <input
                type="number" 
                step="0.01"
                {...register("senia", { required: true, min: 0 })}
                placeholder="Monto de la seña"
              />
               {errors.senia && <p className="error-message">Este campo es requerido</p>}
            </div>
          </div>
          <div className="form-group-horizontal">
          <div className="form-group columnas">
            <label htmlFor="metodo_pago">Método de Pago: *</label>
            <select {...register("metodo_pago", { required: true })}>
            <option value="">Selecciona un metodo de pago</option>
              <option value="contado">Contado</option>
              <option value="transferencia">Transferencia</option>
              <option value="2_pagos">2 Pagos</option>
              <option value="3_pagos">3 Pagos</option>
            </select>
            {errors.metodo_pago && <p className="error-message">Este campo es requerido</p>}
          </div>
          <div className="form-group columnas">
            <label htmlFor="moneda">Moneda: *</label>
            <select {...register("moneda",  { required: true })}>
            <option value="">Selecciona una moneda</option>
            <option value="reales">Reales</option>
            <option value="pesos_argentinos">Pesos Argentinos</option>  
            <option value="dolares">Dólares</option>  
            <option value="euros">Euros</option>
            <option value="libras">Libras</option>
            </select>
            {errors.moneda && <p className="error-message">Este campo es requerido</p>}
          </div>
        </div>

          <div className="form-group">
            <button className="submit-buttonalq" type="submit">Agregar Alquiler</button>
          </div>
        </form>
      </div>
    </div>
  );
};


import React from 'react';
import ButtonAppBar from '../../components/navBar'
import Sidebar from '../../components/sideBar';
import '../../styles/Calendar.css'; 
import DataTable from '../../components/DataTable';

function NovedadesTable (){
    return(
        <div>
            <ButtonAppBar nombre='Novedades' />
            <div className="main-container">
                <Sidebar />
            <div className="table-container">
                <DataTable />
            </div>
            </div>
        </div>
    )
}
export default NovedadesTable
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import NovedadesTable from './containers/pages/Novedades';
import Calendario from './containers/pages/Calendar';
import GestorPropiedades from './containers/pages/GestorPropiedades';
import GestorClientes from './containers/pages/GestorClientes';
import GestorGastos from './containers/pages/GestorGastos';
import Alquileres from './containers/pages/Alquileres';
import { ToastContainer } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import config from './config';
import CreateUser from './components/CreateUser';
import EditProfile from './components/ModifyUser';
import Init from './components/updateSuccess'


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuthentication = async () => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = await decodeToken(token);

        if (decodedToken) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, [isAuthenticated]);

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Access component={Init} isAuthenticated={isAuthenticated} />} />
          <Route
            path="/login"
            element={<Login onLogin={() => setIsAuthenticated(true)} />}
          />          
          <Route path="/inicio" element= {<Access component={Login} isAuthenticated={isAuthenticated} />} />
          <Route path="/register" element={<Access component={CreateUser} isAuthenticated={isAuthenticated} />} />
          <Route path="/edit_profile" element={<Access component={EditProfile} isAuthenticated={isAuthenticated} />} />
          <Route path="/novedades" element={<Access component={NovedadesTable} isAuthenticated={isAuthenticated} />} />
          <Route path="/calendario" element={<Access component={Calendario} isAuthenticated={isAuthenticated} />} />
          <Route path="/propiedades" element={<Access component={GestorPropiedades} isAuthenticated={isAuthenticated} />} />
          <Route path="/pasajeros" element={<Access component={GestorClientes} isAuthenticated={isAuthenticated} />} />
          <Route path="/gastos" element={<Access component={GestorGastos} isAuthenticated={isAuthenticated} />} />
          <Route path="/alquileres" element={<Access component={Alquileres} isAuthenticated={isAuthenticated} />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

const Access = ({ component: Component, isAuthenticated, onLogin, ...rest }) => {
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

function decodeToken(token) {
  return new Promise((resolve, reject) => {
    try {
      const decoded = jwt_decode(token, config.secretKey);
      resolve(decoded);
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      reject(error);
    }
  });
}

export default App;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateProperties, deleteProperties, getAllPropertiesWithInventario } from '../api/propiedades.api';
import { getAllAlquileres } from '../api/alquileres.api';
import { createInventario, deleteInventario } from '../api/inventario.api';
import { getAllInventario, getInventarioByPropertyId} from '../api/inventario.api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import '../styles/ListaPropiedades.css';
import '../styles/AddPropertiesForm.css';
import jwt_decode from 'jwt-decode';
import config from '../config'; 
const servicios = ['Television', 'Cafetera', 'Microondas', 'FrigoBar', 'Internet', 'Caja Fuerte', 'Secador de pelo', 'Toallas', 'Aire Acondicionado', 'Ventilador','Cocina'];

export const PropiedadesList = ({ searchTerm, setShouldRefresh }) => {
  const [properties, setProperties] = useState([]);
  const [alquileres, setAlquileres] = useState([]);
  const [inventarios, setInventarios] = useState([]);
  const [editingPropertyId, setEditingPropertyId] = useState(null);
  const [editingServicePropertyId, setEditingServicePropertyId] = useState(null);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [propertyToDelete, setPropertyToDelete] = useState(null);
  const [serviciosInventario, setServiciosInventario] = useState({});
  const [servicesVisible, setServicesVisible] = useState({});
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isServiceEditDialogOpen, setIsServiceEditDialogOpen] = useState(false);
  const [tempSelectedServices, setTempSelectedServices] = useState({});
  const [serviceDescriptions, setServiceDescriptions] = useState({});
  const [updateIndicator, setUpdateIndicator] = useState(0);
  const [isSuperuser, setIsSuperuser] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token, config.secretKey);

        setIsSuperuser(decodedToken.is_superuser);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  }, []);


  const deleteProperty = async (propertyId) => {
    try {
      await deleteProperties(propertyId);
      setProperties(properties.filter((item) => item.id !== propertyId));
      toast.success('Propiedad eliminada exitosamente.');
    } catch (error) {
      toast.error('Error al eliminar propiedad');
    }
  };

  const openEditDialog = (property) => {
    setEditingPropertyId(property.id);    
    setValue("nombre", property.nombre);
    setValue("direccion", property.direccion);
    setValue("provincia", property.provincia);
    setValue("localidad", property.localidad);
    setValue("codigoPostal", property.codigoPostal);
    setValue("cantAmbientes", property.cantAmbientes);
    setValue("descripcion", property.descripcion);
    setValue("valorDiario", property.valorDiario);
    setIsEditDialogOpen(true);
  };

  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openServiceEditDialog = (property) => {
    setEditingServicePropertyId(property.id);
    setIsServiceEditDialogOpen(true);
  };

  const closeServiceEditDialog = () => {
    setIsServiceEditDialogOpen(false);
  };

  const saveServiceChanges = async () => {

    // Actualiza la API con los cambios de servicios
    try {
      const updatedServices = tempSelectedServices[editingServicePropertyId] || [];
      const propertyId = editingServicePropertyId;
  
      // Obtiene los servicios de inventario existentes para esta propiedad
      const existingServices = serviciosInventario[propertyId] || {};
  
      // Elimina los servicios de inventario que ya no están seleccionados
      for (const serviceName in existingServices) {
        if (!updatedServices.includes(serviceName)) {
          const serviceIdToDelete = existingServices[serviceName].id;
          await deleteInventario(serviceIdToDelete);
        }
      }
  
      // Agrega los nuevos servicios de inventario seleccionados
      for (const service of updatedServices) {
        const serviceDescription = serviceDescriptions[service] || ''; // Obtén la descripción del estado
        await createInventario({
          propiedad: propertyId,
          nombre: service,
          descripcion: serviceDescription,
        });
      }
      const updatedProperties = await getAllPropertiesWithInventario();
      setProperties(updatedProperties);
      // Cierra el diálogo de edición de servicios
      closeServiceEditDialog();

      setUpdateIndicator((prevIndicator) => prevIndicator + 1);
      
      toast.success('Cambios en servicios guardados correctamente.');
    } catch (error) {
      toast.error('Error al guardar cambios en servicios.');
    }
  };
  

  
  const handleServiceSelection = (propertyId, service) => {
    setTempSelectedServices((prevState) => ({
      ...prevState,
      [propertyId]: prevState[propertyId]
        ? prevState[propertyId].includes(service)
          ? prevState[propertyId].filter((s) => s !== service)
          : [...prevState[propertyId], service]
        : [service],
    }));
  };
  
  const handleServiceDescriptionChange = (service, description) => {
    setServiceDescriptions((prevState) => ({
      ...prevState,
      [service]: description,
    }));
  };
  

  const handleDeleteProperty = async (propertyId) => {
    const hasAlquileres = alquileres.some(
      (alquiler) => alquiler.propiedad === propertyId
    );
    if (hasAlquileres) {
      toast.error('No se puede eliminar esta propiedad porque tiene alquileres registrados.');
    } else {
      setPropertyToDelete(propertyId);
      setIsDeleteDialogOpen(true);
    }
  };

  const filterProperties = (property) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      property.nombre.toLowerCase().includes(lowerSearchTerm) ||
      property.codigoPostal.toString().includes(searchTerm) ||
      property.direccion.toLowerCase().includes(lowerSearchTerm) ||
      property.provincia.toLowerCase().includes(lowerSearchTerm)
    );
  };

  const filteredProperties = properties.filter(filterProperties);

  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append('nombre', data.nombre);
    formData.append('direccion', data.direccion);
    formData.append('provincia', data.provincia);
    formData.append('localidad', data.localidad);
    formData.append('codigoPostal', data.codigoPostal);
    formData.append('cantAmbientes', data.cantAmbientes);
    formData.append('descripcion', data.descripcion);
    formData.append('valorDiario', data.valorDiario);

    if (data.imagen && data.imagen[0]) {
      formData.append('imagen', data.imagen[0]);
    }

    try {
      const response = await updateProperties(editingPropertyId, formData);
      const propiedadId = response.data.id;
      setProperties(properties.map((property) =>
        property.id === propiedadId ? { ...property, ...data } : property));

      setEditingPropertyId(null);
      setIsEditDialogOpen(false);
      navigate('/propiedades');
      toast.success('Propiedad modificada correctamente');
    } catch (error) {
      toast.error('Error al modificar propiedad');
    }
  });

  const loadServiciosInventario = async (propertyId) => {
    try {
      const inventarioResponse = await getInventarioByPropertyId(propertyId);
      const serviciosInventarioData = {};

      inventarioResponse.forEach((servicio) => {
        serviciosInventarioData[servicio.nombre] = {
          id: servicio.id,
          descripcion: servicio.descripcion,
        };
      });

      setServiciosInventario((prevState) => ({
        ...prevState,
        [propertyId]: serviciosInventarioData,
      }));

      return serviciosInventarioData;
    } catch (error) {
      console.error("Error al cargar servicios de inventario:", error);
      return {};
    }
  };

  useEffect(() => {
    async function loadData() {
      try {
        const propertiesResponse = await getAllPropertiesWithInventario();
        const alquileresResponse = await getAllAlquileres();
        const inventariosResponse = await getAllInventario();

        setProperties(propertiesResponse);
        setAlquileres(alquileresResponse.data);
        setInventarios(inventariosResponse);

        const servicesVisibleInit = {};
        propertiesResponse.forEach((property) => {
          servicesVisibleInit[property.id] = false;
        });
        setServicesVisible(servicesVisibleInit);

        if (editingPropertyId !== null) {
          const propertyToEdit = propertiesResponse.find((property) => property.id === editingPropertyId);
          if (propertyToEdit) {
            await loadServiciosInventario(propertyToEdit.id);
          }
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    }
    loadData();
  }, [editingPropertyId, updateIndicator]);

  useEffect(() => {
    if (editingPropertyId !== null) {
      const propertyToEdit = properties.find(property => property.id === editingPropertyId);
      if (propertyToEdit) {
        setValue("nombre", propertyToEdit.nombre);
        setValue("direccion", propertyToEdit.direccion);
        setValue("provincia", propertyToEdit.provincia);
        setValue("localidad", propertyToEdit.localidad);
        setValue("codigoPostal", propertyToEdit.codigoPostal);
        setValue("cantAmbientes", propertyToEdit.cantAmbientes);
        setValue("descripcion", propertyToEdit.descripcion);
        setValue("valorDiario", propertyToEdit.valorDiario);
      }
    }
  }, [editingPropertyId, properties, setValue]);

  return (
    <div className="container-properties">
      {filteredProperties.length === 0 ? (
        <p>No hay propiedades para mostrar.</p>
      ) : (
        <div className="table-container">
          {filteredProperties.map((property) => (
            <div key={property.id} className="item_propiedad">
              <div className="item_content">
                {property.imagen ? (
                  <img
                    className="imagen_item"
                    src={property.imagen}
                    alt="Imagen de Propiedad"
                  />
                ) : (
                  <img
                    className="imagen_item"
                    src={`${config.baseUrl}media/media/ginmueble.jpg`}
                    alt="Imagen de Propiedad Genérica"
                  />
                )}
                <div className="texto_contenedor">
                  <h3>
                    <b>{property.direccion} - {property.nombre}</b>
                  </h3>
                  <p>(CP: {property.codigoPostal}  /  Valor Diario: {property.valorDiario})</p>
                  <p>
                    {property.provincia} - {property.localidad}
                  </p>
                </div>
                <div className="button-container-prop">
                  {isSuperuser && (
                  <Button
                    className="button_edit-prop"
                    onClick={() => { openEditDialog(property) }}
                  >
                    <EditIcon />
                  </Button>
                   )}
                   {isSuperuser && (
                  <Button
                    className="button_eliminate-prop"
                    onClick={() => handleDeleteProperty(property.id)}
                  >
                    <DeleteIcon />
                  </Button>
                  )}


                  <Button
                    className="button_ver-servicios"
                    onClick={() => {
                      setServicesVisible((prevState) => ({
                        ...prevState,
                        [property.id]: !prevState[property.id],
                      }));
                    }}
                  >
                    <b>{servicesVisible[property.id] ? '-' : '+'}</b>
                  </Button>
                </div>
              </div>
              {servicesVisible[property.id] && (
                <div className="servicios-container">
                  <p>Servicios:</p>
                  <ul>
                  {inventarios.map((item, index) => {
                  if (item.propiedad === property.id) {
                    const handleDeleteService = async () => {
                      try {
                        await deleteInventario(item.id);
                      
                        const updatedInventarios = inventarios.filter((service) => service.id !== item.id);
                        setInventarios(updatedInventarios);
                        
                        toast.success('Servicio de inventario eliminado correctamente.');
                      } catch (error) {
                        toast.error('Error al eliminar el servicio de inventario.');
                      }
                    };

                    return (
                      <div key={index}>
                        <li>
                          <b>{item.nombre}</b>: {item.descripcion} 
                          {isSuperuser &&(<Button onClick={handleDeleteService}><DeleteIcon /></Button>)}
                        </li>
                      </div>
                    );
                  }
                  return null;
                })}
                  </ul>
                  {isSuperuser && (
                  <Button
                    onClick={() => {openServiceEditDialog(property)}}
                  >
                    <EditIcon /> Añadir Servicios
                  </Button>
                  )}
                </div>
              )}

              <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Confirmar Eliminación</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    ¿Estás seguro de que deseas eliminar esta propiedad?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      setIsDeleteDialogOpen(false);
                      deleteProperty(propertyToDelete);
                    }}
                    color="primary"
                    autoFocus
                  >
                    Eliminar
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={isEditDialogOpen}
                onClose={() => closeEditDialog()}
                aria-labelledby="edit-dialog-title"
                
              >
                <DialogTitle id="edit-dialog-title">Editar Propiedad</DialogTitle>
                <DialogContent>
                  <form className="add-properties-form edicionPropiedad" onSubmit={onSubmit}>
                    <input type="text" placeholder='Nombre' {...register("nombre", { required: true })} />
                    {errors.nombre && <span className="error-message">Este campo es requerido</span>}

                    <input type="text" placeholder='Direccion' {...register("direccion", { required: true })} />
                    {errors.direccion && <span className="error-message">Este campo es requerido</span>}

                    <input type="text" placeholder='Provincia' {...register("provincia", { required: true })} />
                    {errors.provincia && <span className="error-message">Este campo es requerido</span>}

                    <input type="text" placeholder='Localidad' {...register("localidad", { required: true })} />
                    {errors.localidad && <span className="error-message">Este campo es requerido</span>}

                    <input type="text" placeholder='Codigo Postal' {...register("codigoPostal", { required: true })} />
                    {errors.codigoPostal && <span className="error-message">Este campo es requerido</span>}

                    <input type="text" placeholder='Cantidad de Ambientes' {...register("cantAmbientes", { required: true })} />
                    {errors.cantAmbientes && <span className="error-message">Este campo es requerido</span>}

                    <input type="number" placeholder='Valor Diario' {...register("valorDiario")} />

                    <textarea rows="3" placeholder='Descripcion del inmueble' {...register("descripcion")} />

                    <div className="button-container">
                      <Button className="button_cancel" onClick={() => closeEditDialog()}>Cancelar</Button>
                      <Button type="submit" className="button_add">Guardar Cambios</Button>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>

              <Dialog
                open={isServiceEditDialogOpen}
                onClose={() => closeServiceEditDialog()}
                aria-labelledby="service-edit-dialog-title"
              >
                <DialogTitle id="service-edit-dialog-title">Añadir Servicios</DialogTitle>
                <DialogContent>
                  <form>
                  {servicios.map((service, index) => (
                  <div key={index} className="service-edit-item">
                    <input
                      type="checkbox"
                      name={service}
                      id={`check-${service}`}
                      onChange={() => handleServiceSelection(editingServicePropertyId, service)}
                    />
                    <label htmlFor={`check-${service}`} className="service-label">{service}</label>
                    {tempSelectedServices[editingServicePropertyId]?.includes(service) && (
                      <input
                        type="text"
                        id={`${service}Description`}
                        className="service-description-input"
                        placeholder="Descripción"
                        onChange={(e) => handleServiceDescriptionChange(service, e.target.value)}
                        style={{marginLeft:'10px'}}
                      />
                    )}
                  </div>
                ))}
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => closeServiceEditDialog()}>Cancelar</Button>
                  <Button onClick={() => saveServiceChanges()} variant="contained" color="primary">Guardar</Button>
                </DialogActions>
              </Dialog>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};






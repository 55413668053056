import React from 'react'
import ButtonAppBar from '../../components/navBar'
import Sidebar from '../../components/sideBar';
import GastosLabTabs from '../../components/GastosTabItems';
import '../../styles/Calendar.css'; 

const GestorGastos = () => {
  return (
    <div>
    <ButtonAppBar nombre='Gastos' />
      <div>
        <Sidebar />
        <div div style={{ display: 'flex', alignItems:'center', width: '100%'}}>
          <GastosLabTabs           
          item1='Listado de Gastos' 
          item2='Añadir Gasto'/>
        </div>
      </div>
    </div>
  )
}

export default GestorGastos
import React, { useEffect, useState } from 'react';
import { getAllAlquileres } from '../api/alquileres.api';
import { getPropiedadById } from '../api/propiedades.api';
import { getInquilinoById } from '../api/clientes.api';
import '../styles/DataTable.css';

export default function DataTable() {
  const [alquileres, setAlquileres] = useState([]);
  const [notificationMessages, setNotificationMessages] = useState([]);
  const [hasNotifications, setHasNotifications] = useState(false);

  const fetchNotifications = async (alquileresData) => {
    const now = new Date();
    const offset = -3; // UTC offset for Argentina (GMT-3)
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const today = new Date(utc + 3600000 * offset);

    const notifications = await Promise.all(
      alquileresData.map(async (alquiler) => {
        const fechaInicio = new Date(alquiler.fecha_inicio);
        const fechaFin = new Date(alquiler.fecha_fin);
        fechaFin.setUTCHours(21, 59, 59);
        fechaFin.setDate(fechaFin.getDate() - 1);
    
        const daysRemaining = Math.ceil((fechaFin - today) / (1000 * 60 * 60 * 24));
        const venceHoy = daysRemaining === 0;
    
        try {
          const propiedad = await getPropiedadById(alquiler.propiedad);
          const propiedadDireccion = propiedad.direccion;

          const pasajero = await getInquilinoById(alquiler.inquilino);
          const pasajeroNombre = pasajero.nombre;
          const pasajeroApellido = pasajero.apellido;
    
          const inicioPasado = fechaInicio <= today;
    
          if (daysRemaining <= -1 && daysRemaining >= -3) {
            return (
              <div key={alquiler.id} className="notification expired">
                <p>
                  <strong>Alquiler Vencido</strong>
                  <br />
                  El alquiler correspondiente a la dirección "{propiedadDireccion}" (Pasajero: {pasajeroNombre} {pasajeroApellido}) se encuentra <b>VENCIDO</b>.
                </p>
              </div>
            );
          } else if (venceHoy) {
            return (
              <div key={alquiler.id} className="notification ending-today">
                <p>
                  <strong>Alquiler Próximo a Vencer</strong>
                  <br />
                  El alquiler correspondiente a la dirección "{propiedadDireccion}" (Pasajero: {pasajeroNombre} {pasajeroApellido}) vence <b>HOY</b>.
                </p>
              </div>
            );
          } else if (daysRemaining <= 3 && daysRemaining >= -3 && daysRemaining !== venceHoy && inicioPasado) {
            return (
              <div key={alquiler.id} className="notification ending-soon">
                <p>
                  <strong>Alquiler Próximo a Vencer</strong>
                  <br />
                  El alquiler correspondiente a la dirección "{propiedadDireccion}" (Pasajero: {pasajeroNombre} {pasajeroApellido}) está próximo a vencer en <b>{daysRemaining} DÍAS</b>.
                </p>
              </div>
            );
          } else {
            return null;
          }
        } catch (error) {
          console.error('Error al obtener los datos de la propiedad:', error);
          return null;
        }
      })
    );

    setNotificationMessages(notifications);
    setHasNotifications(notifications.some(notification => notification !== null));
  };

  useEffect(() => {
    async function loadAlquileres() {
      const res = await getAllAlquileres();
      setAlquileres(res.data);
    }
    loadAlquileres();
  }, []);

  useEffect(() => {
    if (alquileres.length === 0) {
      setNotificationMessages([]);
      setHasNotifications(false);
      return;
    }
  
    fetchNotifications(alquileres);
  }, [alquileres]);
  

  useEffect(() => {
    // Limpiar notificaciones y cargar nuevamente al recargar la página
    setNotificationMessages([]);
    fetchNotifications(alquileres);
  }, [alquileres]);

  return (
    <div className="notifications-container">
      {hasNotifications ? (
        <div className="agenda">
          {notificationMessages}
        </div>
      ) : (
        <div className="no-notifications-message">
          No hay novedades que mostrar.
        </div>
      )}
    </div>
  );
}


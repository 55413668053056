import React from 'react';
import ButtonAppBar from '../../components/navBar'
import Sidebar from '../../components/sideBar';
import CalendarAlq from '../../components/Calendar';
import '../../styles/Calendar.css'; 



function Calendario (){
    return(
        <div>
            <ButtonAppBar nombre='Calendario' />
            <div className="main-container">
                <Sidebar />
            <div className="calendar-container">
                <div id="calendar">
                    <link rel="stylesheet" href="/fullcalendar-styles.html" />
                <CalendarAlq />
                </div>
            </div>
            </div>
        </div>
    )
}
export default Calendario
import axios from 'axios';
import config from '../config';

const propertiesApi = axios.create({
  baseURL: `${config.baseUrl}propiedades/api/v1/propiedades`
});

export const getAllProperties = async () => {
  try {
    const response = await propertiesApi.get('/');
    return response.data; // Retorna las propiedades tal como vienen
  } catch (error) {
    throw error;
  }
};

export const createProperties = (formData) => {
  return propertiesApi.post('/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data' // Importante establecer el tipo de contenido adecuado
    }
  }); 
};


export const deleteProperties = (id) => {
  return propertiesApi.delete(`/${id}`);
};

export const updateProperties = (id, properties) => {
  return propertiesApi.put(`/${id}/`,properties)
};

export const searchProperties = (id) => {
  return propertiesApi.get(`/${id}`);
}

export const getPropiedadById = async (propiedadId) => {
  try {
    const response = await propertiesApi.get(`/${propiedadId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getServiceById = (servicioId) => {
  return axios.get(`${config.baseUrl}servicios/api/v1/servicios/${servicioId}`);
};


export const getAllPropertiesWithInventario = async () => {
  try {
    const response = await propertiesApi.get('/');
    const propertiesWithInventario = await Promise.all(
      response.data.map(async (property) => {
        const inventarioResponse = await axios.get(
          `${config.baseUrl}inventario/api/v1/inventario/?propiedad=${property.id}`
        );
        const inventarioData = inventarioResponse.data.map((item) => ({
          nombre: item.nombre,
          descripcion: item.descripcion,
        }));
        return {
          ...property,
          inventario: inventarioData,
        };
      })
    );
    return propertiesWithInventario;
  } catch (error) {
    throw error;
  }
};
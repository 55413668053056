import React from 'react'
import Sidebar from '../../components/sideBar';
import ButtonAppBar from '../../components/navBar';
import AlquileresLabTabs from '../../components/AlquileresTabItems';

const Alquileres = () => {
  return (
    <div>
        <ButtonAppBar nombre='Alquileres'/>
        <div>
        <Sidebar  />
        </div>
      <div style={{ display: 'flex', alignItems:'center'}}>
          <AlquileresLabTabs 
          item1='Lista de Alquileres' 
          item2='Añadir Alquiler'/>
      </div>
    </div>
  )
}

export default Alquileres
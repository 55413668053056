import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountIcon from '@mui/icons-material/AccountCircle';
import HouseIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import CreateIcon from '@mui/icons-material/Create';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import jwt_decode from 'jwt-decode';
import config from '../config';
import { toast } from 'react-toastify';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import '../styles/sideBar.css';

const drawerWidth = 200;

function Sidebar() {
  const [username, setUsername] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token, config.secretKey);

        setUsername(decodedToken.username);
        setIsSuperuser(decodedToken.is_superuser);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    toast.success('Sesión cerrada correctamente');
    navigate('/login');
  };

  const drawer = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
<List>
        <ListItem button className="button_sidebar" >
          <ListItemIcon sx={{ color: '#fff' }}>
            <AccountIcon fontSize="large" />
          </ListItemIcon>
          {username && (
            <ListItemText primary={username} sx={{ color: '#fff' }} />
          )}
        </ListItem>
      
        
        <Link to="/propiedades" className="sidebar-link">
        <ListItem button className="button_sidebar">
          <ListItemIcon sx={{ color: '#fff' }} > 
          <HouseIcon />
          </ListItemIcon>
          <ListItemText primary="Propiedades" sx={{ color: '#fff' }} />
        </ListItem>
        </Link>

        <Link to="/alquileres" className="sidebar-link">
        <ListItem button className="button_sidebar">
          <ListItemIcon sx={{ color: '#fff' }}> 
          <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Alquileres" sx={{ color: '#fff' }} />
        </ListItem>
        </Link>

        <Link to="/pasajeros" className="sidebar-link">
        <ListItem button className="button_sidebar create-user">
          <ListItemIcon sx={{ color: '#fff' }}> 
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Pasajeros" sx={{ color: '#fff' }} /> 
        </ListItem>
        </Link>

        {isSuperuser && (
        <Link to="/gastos" className="sidebar-link">
        <ListItem button className="button_sidebar">
          <ListItemIcon sx={{ color: '#fff' }}>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Gastos" sx={{ color: '#fff' }} /> 
        </ListItem>
        </Link>
        )}
      </List>

      <div style={{ marginTop: 'auto' }}>
        {/* Opción para crear nuevo usuario */}
        {isSuperuser && (
          <Link to="/register" className="sidebar-link">
            <ListItem button className="button_sidebar">
              <ListItemIcon sx={{ color: '#fff' }}>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText
                primary="Crear Nuevo Usuario"
                sx={{ color: '#fff' }}
              />
            </ListItem>
          </Link>
        )}

        {/* Opción para editar perfil */}
        <Link to="/edit_profile" className="sidebar-link">
          <ListItem button className="button_sidebar">
            <ListItemIcon sx={{ color: '#fff' }}>
              <CreateIcon />
            </ListItemIcon>
            <ListItemText primary="Editar Perfil" sx={{ color: '#fff' }} />
          </ListItem>
        </Link>

        {/* Opción para cerrar sesión */}
        <ListItem button className="button_sidebar" onClick={handleLogout}>
          <ListItemIcon sx={{ color: '#fff' }}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar Sesión" sx={{ color: '#fff' }} />
        </ListItem>
      </div>
    </div>
  );

  return (
    <div>
      {/* Botón o icono para abrir la barra lateral en dispositivos móviles */}
      <Hidden mdUp>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginRight: 2, display: { sm: 'none' }, zIndex:'1000' }}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>

      {/* Barra lateral */}
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor: '#162b4f',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      {/* Barra lateral en dispositivos móviles */}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor: '#162b4f',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}

export default Sidebar;

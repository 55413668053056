import axios from 'axios';
import config from '../config'; 

const alquileresApi = axios.create({
    baseURL: `${config.baseUrl}alquileres/api/v1/alquileres`
  });

  export const getAllAlquileres = () => {
    return alquileresApi.get('/');
  };

  export const getAlquileresById = async (alquilerId) => {
    try {
      const response = await alquileresApi.get(`/${alquilerId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  export const createAlquileres = (formData) => {
    return alquileresApi.post('/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data' // Importante establecer el tipo de contenido adecuado
      }
    }); 
  };

  export const deleteAlquileres = (id) => {
    return alquileresApi.delete(`/${id}`);
  };

export const updateAlquileres = async (alquilerId, alquilerData) => {
  return alquileresApi.put(`/${alquilerId}/`, alquilerData)
};

import React from 'react';
import ReactDOM from 'react-dom';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { toast } from 'react-toastify';

Font.register({ family: 'Helvetica-Bold', src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Helvetica-Bold.ttf' });

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 10,
  },
  section: {
    margin: 10,
  },
  header: {
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
  },
  table: {
    width: 540, // Ancho máximo de la tabla
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    flex: 1, // Cada celda se ajustará automáticamente dentro del ancho máximo
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 5.5,
  },
  tableCantDias: {
    flex: 1, // Cada celda se ajustará automáticamente dentro del ancho máximo
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    borderStyle: 'solid',
    textAlign: 'center',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 5.5,
    maxWidth: '20px',
  },
  bold: {
    fontFamily: 'Helvetica-Bold', // Usa la fuente negrita
  },
});

const AlquilerReport = ({ reportData }) => {
  let totalValor = 0;
  let totalTasaLimpieza = 0;
  let totalValorCalculado = 0;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Informe de Alquileres</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Propiedad</Text>
              </View>
              <View style={[styles.tableCell, { paddingRight: 5 }]}>
                <Text style={styles.bold}> Inquilino</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Fecha de Inicio</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Fecha de Fin</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Valor diario prom.</Text>
              </View>
              <View style={styles.tableCantDias}>
                <Text style={styles.bold}> Cant. días</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Valor</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Seña</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Tasa de Limp.</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Valor Total</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Método de Pago</Text>
              </View>
              <View style={styles.tableCell}>
                <Text style={styles.bold}> Valor Adeudado</Text>
              </View>
            </View>
            {reportData.map((row, index) => {
              totalValor += parseFloat(row.valor);
              totalTasaLimpieza += parseFloat(row.tasa_limpieza);
              totalValorCalculado += parseFloat(row.valorCalculado);

              return (
                <View style={styles.tableRow} key={index}>
                <View style={styles.tableCell}>
                  <Text> {row.propiedad}  </Text>
                </View>
                <View style={[styles.tableCell, { paddingRight: 5 }]}>
                  <Text> {row.nombre_inquilino} {row.apellido_inquilino}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.fecha_inicio}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.fecha_fin}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.valorPromedio}</Text>
                </View>
                <View style={styles.tableCantDias}>
                  <Text> {row.cantDias}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.valor}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.senia}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.tasa_limpieza}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.valorCalculado}</Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>
                    {row.metodo_pago === '2_pagos'
                      ? '\u00A02 pagos'
                      : row.metodo_pago === '3_pagos'
                      ? '\u00A03 pagos'
                      : ' ' + row.metodo_pago
                    }
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text> {row.valorAdeudado}</Text>
                </View>
              </View>
              );
            })}
            {/* Filas para mostrar los totales */}
            <View style={styles.tableRow}>
                <View style={styles.tableCell}>
                  <Text style={styles.bold}> Totales: </Text>
                </View>
                <View style={[styles.tableCell, { paddingRight: 5 }]}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell}>
                  <Text></Text>
                </View>
                <View style={styles.tableCantDias}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.bold}> {totalValor.toFixed(2)} </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.bold}> {totalTasaLimpieza.toFixed(2)} </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text style={styles.bold}> {totalValorCalculado.toFixed(2)} </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text>
                  </Text>
                </View>
                <View style={styles.tableCell}>
                  <Text></Text>
                </View>
              </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const generatePDFAlquilerReport = (reportData) => {
  const newWindow = window.open('', '_blank');
  if (newWindow) {
    const pdfContent = (
      <PDFViewer width="100%" height="850px" margin="0px" padding="0px">
        <AlquilerReport reportData={reportData} />
      </PDFViewer>
    );
    ReactDOM.render(pdfContent, newWindow.document.body);
  } else {
    toast.error('No se pudo abrir una nueva pestaña para el informe');
  }
};

export default AlquilerReport;

import React from 'react'
import ButtonAppBar from '../../components/navBar'
import Sidebar from '../../components/sideBar';
import '../../styles/Calendar.css'; 
import ClientLabTabs from '../../components/ClientesTabItems';

const GestorClientes = () => {
  return (
    <div>
    <ButtonAppBar nombre='Pasajeros' />
      <div>
        <Sidebar />
        <div style={{ display: 'flex', alignItems:'center'}}>
          <ClientLabTabs 
          item1='Lista de Pasajeros' 
          item2='Añadir Pasajero'/>
      </div>
      </div>
    </div>
  )
}

export default GestorClientes
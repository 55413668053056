import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createAcompanante } from '../api/acompanantes.api';

const CreateAcompananteForm = ({ inquilinoId, onAcompananteCreated }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      // Agrega el ID del inquilino al objeto de datos antes de enviarlo
      data.inquilino = inquilinoId;

      // Envía la solicitud para crear un acompañante
      const response = await createAcompanante(data);

      if (response.status === 201) {
        // Limpia el formulario y actualiza la lista de acompañantes
        toast.success('Acompañante añadido correctamente');
        onAcompananteCreated();
      } else {
        // Maneja cualquier error que pueda ocurrir durante la creación
        console.error('Error al crear el acompañante');
        toast.error('Error al añadir acompañante');
      }
    } catch (error) {
      console.error('Error al crear el acompañante', error);
    }
  };

  return (
    <div style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)' }}>
      <h4 style={{ marginTop: '0px', fontSize: '18px' }}>Crear Acompañante</h4>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {/* Agrega campos para el acompañante aquí */}
        <input
          type="text"
          placeholder="Nombre del Acompañante"
          {...register('nombre', { required: true })}
          style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '3px', fontSize: '16px' }}
        />
        {errors.nombre && <span style={{ color: '#ff0000', fontSize: '14px', marginTop: '4px' }}>Este campo es requerido</span>}

        <input
          type="text"
          placeholder="Apellido del Acompañante"
          {...register('apellido', { required: true })}
          style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '3px', fontSize: '16px' }}
        />
        {errors.apellido && <span style={{ color: '#ff0000', fontSize: '14px', marginTop: '4px' }}>Este campo es requerido</span>}

        <input
          type="text"
          placeholder="DNI del Acompañante"
          {...register('dni')}
          style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '3px', fontSize: '16px' }}
        />
        {errors.dni && <span style={{ color: '#ff0000', fontSize: '14px', marginTop: '4px' }}>Este campo es requerido</span>}

        <input
          type="text"
          placeholder="CPF del Acompañante"
          {...register('cpf')}
          style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '3px', fontSize: '16px' }}
        />
        {errors.cpf && <span style={{ color: '#ff0000', fontSize: '14px', marginTop: '4px' }}>Este campo es requerido</span>}

        <input
          type="text"
          placeholder="Edad del Acompañante"
          {...register('edad', { required: true })}
          style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '3px', fontSize: '16px' }}
        />
        {errors.edad && <span style={{ color: '#ff0000', fontSize: '14px', marginTop: '4px' }}>Este campo es requerido</span>}

        <button
          type="submit"
          style={{ backgroundColor: '#1d335a', color: '#fff', border: 'none', borderRadius: '3px', padding: '10px 15px', fontSize: '16px', cursor: 'pointer' }}
        >
          Crear Acompañante
        </button>
      </form>
    </div>
  );
};

export default CreateAcompananteForm;


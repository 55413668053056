import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import '../styles/LabTabs.css';
import { PropiedadesList } from './PropiedadesList';
import { Addproperties } from './addproperties';
import jwt_decode from 'jwt-decode';
import config from '../config'; 
import ValorPropiedadForm from './ValorPropiedadForm';

export default function LabTabs(props) {
  const [value, setValue] = useState('1');
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSuperuser, setIsSuperuser] = useState(false);

  const onChangeTab = (event,newValue) => {
    setValue(newValue);
  };
  

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false); // Resetea el estado
      onChangeTab(null, '1'); // Cambia automáticamente al tab de "Lista de Propiedades"
    }
  }, [shouldRefresh]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwt_decode(token, config.secretKey);

        setIsSuperuser(decodedToken.is_superuser);
      } catch (error) {
        console.error('Error al decodificar el token:', error);
      }
    }
  }, []);

  return (
    <div className="centered-container">
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={onChangeTab}
              aria-label="lab API tabs example"
              className="items_center"
              sx={{ display: 'inline-flex' }} // Agregamos este estilo para mantener en línea
            >
              <Tab label={props.item1} value="1" />
              {isSuperuser && (
              <Tab label={props.item2} value="2" />
              )}
              {isSuperuser && (
              <Tab label={props.item3} value="3" />
              )}
            </TabList>
          </Box>
          <div className="tab-panels">
            <TabPanel value="1">
            <div className="search-container">
              {/* Input de búsqueda */}
              <input
                type="text"
                className="search-input"
                placeholder="Buscar por nombre, código postal o dirección"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)} // Actualiza el estado aquí
              />
            </div>
              <PropiedadesList searchTerm={searchTerm} setShouldRefresh={setShouldRefresh} /> {/* Pasa searchTerm como prop */}
            </TabPanel>
            {isSuperuser &&(
            <TabPanel value="2">
              <ValorPropiedadForm setShouldRefresh={setShouldRefresh}  onChangeTab={onChangeTab}/>
            </TabPanel>
            )}
            {isSuperuser &&(
            <TabPanel value="3">
              <Addproperties setShouldRefresh={setShouldRefresh} />
            </TabPanel>
            )}
          </div>
        </TabContext>
      </Box>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
import config from '../config';

function EditProfile() {
  const [newUsername, setNewUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error('No estás autenticado');
      navigate('/login');
    } else {
      const decodedToken = jwt_decode(token, config.secretKey);
      setUsername(decodedToken.username);
      setNewUsername(decodedToken.username);
    }
  }, [navigate]);

  const handleUpdateProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      
      if (!token) {
        toast.error('No estás autenticado');
        navigate('/login');
        return;
      }

      const headers = {
        'Authorization': `JWT ${token}`,
        'Content-Type': 'application/json',
      };

      const data = {
        new_username: newUsername,
        new_password: newPassword,
      };

      const response = await fetch(`${config.baseUrl}update_profile/`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        toast.success('Perfil actualizado correctamente');
        navigate('/');
      } else {
        toast.error('Error al actualizar el perfil');
      }
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#1d335a' }}>
      <Container maxWidth="xs" style={{ paddingTop: '80px', backgroundColor: 'transparent' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" component="div" gutterBottom>
            Editar Perfil
          </Typography>
          <form>
            <TextField
              label="Nuevo Nombre de Usuario"
              fullWidth
              margin="normal"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder={username}
            />
            <TextField
              type="password"
              label="Nueva Contraseña"
              fullWidth
              margin="normal"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Box mt={2}>
            <Link to="/calendario">
              <Button
                variant="contained"
                style={{ backgroundColor: '#1d335a', color: 'white' }}
                fullWidth
                onClick={handleUpdateProfile}
              >
                Actualizar Perfil
              </Button>
             </Link>
            </Box>
            <Box mt={2}>
              <Link to="/calendario">
                <Button
                  variant="outlined"
                  style={{ borderColor: '#1d335a', color: '#1d335a' }}
                  fullWidth
                >
                  Regresar al Inicio
                </Button>
              </Link>
            </Box>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default EditProfile;




// config.js
const config = {
    //secretKey: 'li-405wl+i8h)nll-0)*xevtzg0it5nw7i7_ou6h*y5apv_d31',  // Reemplaza esto con tu clave secreta real
    secretKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjk4OTU3NjUxLCJpYXQiOjE2OTg5NTczNTEsImp0aSI6ImMxN2U3ZWFlOWUwNTQyZmE4ODIzODk1MjViOTczYTljIiwidXNlcl9pZCI6Mn0.aX2y7To4D9VyWIKeAn-jCWJQJPSKE9gaPaTTDlKc1PA",
    //secretKey: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5OTA0Mzc1MSwiaWF0IjoxNjk4OTU3MzUxLCJqdGkiOiIzNDE1NzQwNGExY2Y0NTAzYmI4ZmI2ODA4ZWU4MjcxNiIsInVzZXJfaWQiOjJ9.BCxeWKxMzWOKS2aVLB4W0uRefc9KdjQHiUnqnmIslaQ",
    //baseUrl: 'http://localhost:8000/',
    //baseUrl: 'http://168.205.92.35:8000/',
    baseUrl: 'https://backend.apartamentoseningleses.com.ar/',
};

export default config;

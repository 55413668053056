import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import config from '../config'; 

function CreateUser() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Realiza una solicitud POST para crear un usuario
    try {
      const response = await fetch(`${config.baseUrl}create_user/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // El usuario se creó exitosamente
        toast.success('Usuario creado exitosamente');
      } else {
        // Hubo un error al crear el usuario
        const data = await response.json();
        toast.error(`Error al crear el usuario: ${data.error}`);
      }
    } catch (error) {
      console.error('Error al crear el usuario:', error);
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#1d335a' }}>
      <Container maxWidth="xs" style={{ paddingTop: '80px', backgroundColor: 'transparent' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h5" component="div" gutterBottom>
            Crear Usuario
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nombre de Usuario"
              fullWidth
              margin="normal"
              name="username"
              value={formData.username}
              onChange={handleChange}
            />
            <TextField
              type="password"
              label="Contraseña"
              fullWidth
              margin="normal"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <Box mt={2}>
              <Button
                variant="contained"
                style={{ backgroundColor: '#1d335a', color: 'white' }}
                fullWidth
                type="submit"
              >
                Crear Usuario
              </Button>
            </Box>
          </form>
          <Box mt={2}>
            <Link to="/calendario">
              <Button
                variant="outlined"
                style={{ borderColor: '#1d335a', color: '#1d335a' }}
                fullWidth
              >
                Regresar al Inicio
              </Button>
            </Link>
          </Box>
        </Paper>
      </Container>
    </div>
  );
}

export default CreateUser;

